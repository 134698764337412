@import "../../styles/shared.scss";

.members-wrapper {

  .wrapped-tabs-wrapper { 

    .arrow {
      background-color: $grey-background;

      &.arrow-left {
        border-right: 10px solid rgba(245, 246, 250, .7);
      }
  
      &.arrow-right {
        border-left: 10px solid rgba(245, 246, 250, .7);
      }
    }
  }

  .all-members-wrapper,
  .requests-wrapper {

    .actions-wrapper {
      display: flex;
      align-items: center;
      height: $size36;
      width: 100%;
      margin-top: $size16;
      margin-bottom: $size16;
      box-sizing: border-box;
    }

    .branch-wrapper {
      max-width: 250px;
    }

    .status-wrapper {
      display: flex;
      gap: $size8;
      align-items: center;
      font-size: $size14;
      white-space: nowrap;

      .status-active {
        color: $success-green;
        display: flex;
        align-items: center;
      }
  
      .status-pending {
        color: $dark-yellow;
        display: flex;
        align-items: center;
      }
  
      .status-inactive {
        display: flex;
        align-items: center;
        color: $dark-grey-blue;      
      }
  
      p {
        margin: 0;
      }
    }
  
    .table-actions-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: end;
      cursor: pointer;
    }
  
    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  .remove-member-confirmation {

    .confirmation-modal-message {
      margin-bottom: $size8;
    }

    .remove-member-confirmation-wrapper {
      padding-left: 56px;

      .disclaimer {
        padding: $size8 $size16;
        border-radius: $element-border-radius;
        background-color: $transparent-blue;
        margin-bottom: $size16;
      }
    }

    .confirmation-modal-buttons {
      margin-top: $size32;
    }
  }

  .accept-member-confirmation,
  .change-admin-confirmation {

    .confirmation-modal-message {
      margin-bottom: $size8;
    }

    .member-confirmation-wrapper,
    .change-admin-confirmation-wrapper {
      padding-left: 56px;

      .disclaimer {
        padding: $size8 $size16;
        border-radius: $element-border-radius;
        background-color: $transparent-blue;
      }

      .title {
        margin-top: $size24;
        font-weight: 500;
        margin-bottom: $size8;
        position: relative;

        svg {
          position: absolute;
          width: 18px;
          height: 18px;
          bottom: 6px;
          cursor: pointer;
        }
      }

      input {
        width: 100%;
        height: $size36;
        padding: $size4 $size16;
        box-sizing: border-box;
        border: 1px solid $medium-grey-blue;
        border-radius: $element-border-radius;
        outline: none;
      }

      .has-error {
        border: 1px solid $required-red;
      }
    }

    .confirmation-modal-buttons {
      margin-top: $size32;
    }
  }

  @media all and (max-width: 768px) {
    .all-members-wrapper,
    .requests-wrapper {

      .actions-wrapper {
        flex-direction: column;
        gap: $size16;
        height: unset;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 0 $size16;
      }

      .order-by-mobile-wrapper {
        margin: $size16;
        height: $size36;
        width: calc(100% - 32px);

        .click-outside-wrapper {
          margin: 0 $size16 0 0;
          border: 1px solid $medium-grey-blue;
          box-shadow: none;
          max-width: 100%;
          cursor: pointer;

          .height {
            div {
              color: $primary-black;
              font-size: $size14;
            }

            svg {
              width: 12px;
              height: 12px;
              path {
                fill: $primary-black;
              }
            }

            .icon-dropdown-open {
              transform: rotate(180deg);
            }
          }

          .options-wrapper {
            margin-top: 4px;
            border: 1px solid $medium-grey-blue;
            max-height: 390px;
            position: relative;
            overflow: overlay;
            z-index: 2;

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }
      }

      .card {
        width: 100%;
        min-height: $size48;
        height: max-content;
        margin-top: $size4;
        background-color: $white;
        padding: $size16 $size8 $size16 $size16;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size8;
        cursor: pointer;
  
        .card-item {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: $size8;
  
          .card-item-title {
            width: 30%;
            font-size: $size14;
            font-weight: 700;
            color: $primary-black;
            margin: 0;
          }
  
          .card-item-body {
            width: 70%;
            padding-right: $size32;
            font-size: $size14;
            color: $primary-black;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: flex-end;
  
            .card-actions {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              position: absolute;
              right: $size16;
  
              .actions-menu-wrapper {
                right: 0;
              }
            }
          }

          .card-item-body-status {
            display: flex;
            gap: $size4;

            .status-active {
              color: $success-green;
              display: flex;
              align-items: center;
            }
        
            .status-pending {
              color: $dark-yellow;
              display: flex;
              align-items: center;
            }
        
            .status-inactive {
              display: flex;
              align-items: center;
              color: $dark-grey-blue;      
            }
        
            p {
              margin: 0;
            }
          }
  
          .align-center {
            align-items: center;
          }
        }
  
        .align-center {
          align-items: center;
        }
      }
  
      .buttons-wrapper {
        margin-top: $size16;
        margin-bottom: $size16;
      }
    }

    .remove-member-confirmation {
      font-size: $size14;

      .remove-member-confirmation-wrapper {
        padding-left: 0;

        .disclaimer {
          p {
            margin: 0;
          }
        }
      }

      .confirmation-modal-buttons {
        flex-direction: column;

        button {
          height: $size36;
        }
      }
    }

    .accept-member-confirmation,
    .change-admin-confirmation {
      font-size: $size14;

      .member-confirmation-wrapper,
      .change-admin-confirmation-wrapper {
        padding-left: 0;

        .disclaimer {
          p {
            margin: 0;
          }
        }
      }

      .confirmation-modal-buttons {
        flex-direction: column;

        button {
          height: $size36;
        }
      }
    }
  }
}