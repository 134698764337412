@import "../../../../styles/shared.scss";

.video-builder-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  .play-icon {
    width: 9.5rem;
    height: 9.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}