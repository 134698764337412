@import "../../../../styles/shared.scss";

.image-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: $element-border-radius;
  background-color: $light-grey-blue;
  width: 100%;
  height: 124px;
  position: relative;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: $element-border-radius;
    border: none;
  }
  
  .remove-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .remove-image:hover {
    background-color: $light-grey-blue;

    svg {

      .fillable-area {
        fill: $danger-red !important; 
      }
    }
  }

  .add-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: $size8;
    cursor: pointer;
    text-align: center;
    color: $primary-blue;
    font-size: $size14;

    .upload-svg {
      position: initial !important;
      transform: unset !important;
    }
  }

  .with-transparency {
    opacity: 0.4;
  }
}