@import "../../../styles/shared";

.rich-text-input {
  .ql-toolbar {
    border-radius: $element-border-radius $element-border-radius 0 0;
  }

  .ql-container {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 $element-border-radius $element-border-radius;
    min-height: 150px;
    max-height: 400px;
    overflow-y: scroll;

    .ql-editor {
      flex-grow: 1;
    }
  }
}

.rich-text-error {
  .ql-snow {
    border: 1px solid $required-red;
  }

  .ql-toolbar {
    border-bottom: 1px solid #ccc !important;
  }
}