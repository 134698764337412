@import "../../styles/shared";

.uploads-wrapper {
  
  .wrapped-tabs-wrapper {

    .arrow {
      background-color: $grey-background;

      &.arrow-left {
        border-right: 10px solid rgba(245, 246, 250, .7);
      }
  
      &.arrow-right {
        border-left: 10px solid rgba(245, 246, 250, .7);
      }
    }
  }

  // .spinner-wrapper {
  //   position: fixed;
  //   z-index: 9999;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   pointer-events: none;

  //   .loading-spinner {
  //     position: relative;
  //     top: 25%;
  //     left: 50%;
  //     transform: translateY(-50%);
  //   }
  // }
}
