@import "../../styles/shared";

.product-details-wrapper {
  display: flex;
  flex-direction: column;

  .dashboard-container {
    .action-button {
      margin-left: auto;
      margin-right: $size8;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;

    @media screen and (max-width: 768px) {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }
  }

  .product-details-container {
    display: flex;
    width: 80vw;
    max-width: 1600px;
    align-self: center;
    justify-content: space-between;
    margin-top: $size16;
    column-gap: $size24;
    box-sizing: border-box;

    .image-slider-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      height: 100%;
      gap: $size8;
      background: $white;
      border-radius: $element-border-radius;
      border: $size16 solid $white;
      box-sizing: border-box;

      .image-slider {
        all: initial;
        width: 100%;
        display: inline-flex;
        will-change: transform, scale;
        cursor: grab;
        flex-shrink: 0;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;

          @media screen and (max-width: 768px) {
            width: 100%;
            height: 345px;
          }
        }

        .slide-outer {
          display: flex;
          align-items: center;
        }

        .image-slide {
          transition: transform 0.2s ease-out;
          height: 100%;
          width: calc(100vw - 82px);

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
          }

          & > img {
            max-width: 100%;
            max-height: 100%;
          }

          @media screen and (max-width: 450px) {
            width: calc(100vw - 64px);
          }
        }

        & > svg {
          width: 100%;
          height: 100%;
        }
      }

      .progress-bar-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $element-border-radius;
        background: $white;
        margin-bottom: $size8;

        .progress-bar-content {
          width: $size14;
          height: $size8;
          margin-left: $size8;
          border-radius: $element-border-radius;
          background: $light-blue-primary;
          transition: 0.3s;
        }

        .unselected {
          width: $size8;
          background: $medium-grey-blue;
        }
      }
    }

    .product-details {
      display: flex;
      flex-direction: column;
      width: 65%;

      .swiper-container,
      .img-swiper-container {
        max-height: 800px;
        width: 100%;
        position: relative;
        display: flex;

        .svg-placeholder {
          width: 100%;
          height: 100%;
          max-width: 409px;
          max-height: 409px;
          align-self: center;
          justify-self: center;
        }

        img {
          flex-shrink: 0;
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 800px;
          max-height: 800px;
          align-self: center;
          justify-self: center;
        }

        .previous-arrow,
        .next-arrow {
          position: absolute;
          top: calc(50% - 24px);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 80px;
          border-radius: $element-border-radius;
          border: 1px solid $medium-grey-blue;
          background: $white;
          cursor: pointer;

          svg {

            path {
              fill: $primary-black;
            }
          }
        }

        .previous-arrow {
          transform: rotate(180deg);
          left: $size16;
        }

        .next-arrow {
          right: $size16;
        }

        .hidden {
          opacity: 0.5;
        }
      }
      
      .swiper-container {
        background-color: $white;
        border-radius: $element-border-radius;
      }

      .product-images {
        display: flex;
        gap: $size8;
        margin-top: $size16;

        .single-product-image {
          width: 80px;
          height: 80px;
          border-radius: $element-border-radius;
          border: 1px solid transparent;
          cursor: pointer;
        }

        .selected-image {
          border: 1px solid $light-blue-primary;
        }
      }

      .tabs-wrapper {
        background-color: $white;
        border-radius: $element-border-radius;
        margin-top: $size32;
        padding: $size16 0 $size16 0;

        .underlined-box-tab {
          padding: 0 $size16;

          .tab-title {
            font-size: $size18;
          }
        }

        .content {
          height: fit-content;
          min-height: fit-content;
          display: flex;
          flex-direction: row;          

          .product-features-wrapper {
            width: 100%;
            min-height: 240px;
            box-sizing: border-box;
            background-color: $white;
            border-radius: $element-border-radius;
            display: flex;
            flex-direction: column;
            gap: $size24;
            padding: $size24 $size16;

            .product-feature {
              display: flex;
              flex-direction: column;
              line-height: 21px;

              .feature-title {
                padding: 0 0 $size8 0;
                font-size: $size18;
                font-weight: 500;
              }
  
              .feature-description {
                font-size: $size14;
              }
            }
          }
        }

        .related-products-flex-wrapper {
          width: 100%;
          min-height: 240px;
          box-sizing: border-box;
          background-color: $white;
          border-radius: $element-border-radius;
          display: flex;
          padding: $size24 $size16;

          .accessories-wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(247px, 1fr));
            grid-gap: $size16;
            width: 100%;

            .wrapper-item {
              display: flex;
              flex-direction: column;
              width: 100%;
              background-color: $light-grey-blue;
              min-height: 247px;
              padding: $size16;
              border-radius: $element-border-radius;
              box-sizing: border-box;
              cursor: pointer;

              .product-image-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 80px;
                background-color: $white;
                border-radius: $element-border-radius;
                flex-shrink: 0;

                img {
                  object-fit: cover;
                  width: 80px;
                  height: 80px;
                  background-size: auto;
                  background-position: center;
                }

                svg {
                  width: 80px;
                  height: 80px;
                }
              }

              .product-info-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;

                .product-type {
                  margin-top: $size16;
                  margin-bottom: $size8;
                  font-size: $size12;
                  color: $dark-grey-blue;
                }

                .product-name {
                  font-weight: 700;
                  font-size: $size16;
                  color: $primary-black;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .product-description {
                  color: $dark-grey-blue;
                  margin-top: $size4;
                  font-size: $size14;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .product-price-wrapper {
                  margin-top: auto;
                  display: flex;
                  justify-content: space-between;
                  min-height: $size36;
                  margin-top: $size16;
                  align-items: flex-start;
                  width: 100%;

                  .prices-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: $size4;

                    .product-price {
                      font-size: $size14;
                      color: $primary-blue;
                      font-weight: 500;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      &.price-sold-out {
                        opacity: 40%;
                        color: rgba(0, 0, 0, 0.4);
                      }
  
                      &.price-discount-line {
                        text-decoration: line-through;
                      }
  
                      &.product-discount {
                        color: #ea3224;
                      }
                    }
                  }
                  
                  .btn-add-to-cart {
                    display: none;
                    height: 36px;
                    width: 36px;
                    align-items: center;
                    justify-content: center;
                    background: $light-blue-primary;
                    border-radius: 18px;
                    flex-shrink: 0;
                    cursor: pointer;
  
                    @media screen and (max-width: 768px) {
                      display: flex;
                    }
                  }

                  .disabled-cart {
                    pointer-events: none;
                    cursor: default;
                    opacity: 0.5;
                  }
                }
              }

              &:hover {
                transform: scale(1.02);

                .product-price-wrapper {
                  .btn-add-to-cart {
                    display: flex !important;
                  }
                }        
              }
            }

            @media screen and (max-width: 768px) {
              grid-gap: $size8;
            }
          }
        }

        .specifications-wrapper {
          width: 100%;
          min-height: 240px;
          box-sizing: border-box;
          background-color: $white;
          border-radius: $element-border-radius;
          padding: $size24 $size16;

          .product-specification {
            line-height: 21px;

            p {
              margin: 0;
            }
          }
        }

        &:hover {
          .product-basket-btn {
            .btn-add-to-cart {
              display: flex;
            }
          }

          @media screen and (max-width: 480px) {
            .product-basket-btn {
              .btn-add-to-cart {
                display: none;
              }
            }
          }
        }
      }
    }

    .checkout-details {
      display: flex;
      background-color: $white;
      padding: $size24;
      height: 100%;
      width: 30%;
      border-radius: $element-border-radius;
      position: sticky;
      top: $size16;
      z-index: 1;
      box-sizing: border-box;

      .product-summary-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .click-outside-wrapper {
          margin: 0 0 $size24 0;
          border: 1px solid $medium-grey-blue;
          box-shadow: none;
          max-width: 240px;
          box-sizing: border-box;
          cursor: pointer;

          .height {

            div {
              color: $primary-black;
              font-size: $size14;
            }         

            svg {
              width: 12px;
              height: 12px;
              path {
                fill : $primary-black;
              }
            }
  
            .icon-dropdown-open {
              transform: rotate(180deg);
            }

            .text-wrapper {
              text-indent: $size16;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
  
          .options-wrapper {
            margin-top: 4px;
            border: 1px solid $medium-grey-blue;
            max-height: 160px;
            position: relative;
            overflow: overlay;

            .option {
              font-size: $size14;
              line-height: 0px;
              padding: $size16 $size16 $size16 $size16;
              text-indent: 0;
              box-sizing: border-box;
              height: unset;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }
        
        .product-type {
          font-size: $size12;
          color: $dark-grey-blue;
          text-align: left;
          margin-bottom: $size8;
        }

        .product-name {
          font-size: $size24;
          font-weight: 700;
          color: $primary-black;
        }

        .product-subtitle {
          margin-top: $size4;
          font-size: $size14;
          color: $dark-grey-blue;
        }

        .product-part-number {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: $size16;
          padding: $size4 $size8;
          background: $light-grey-blue;
          border-radius: $size12;
          width: fit-content;
          color: $primary-black;
          font-size: $size14;

          .product-number {
            font-weight: 500;
            padding-left: $size4;
            color: $primary-blue;
          }
        }

        .product-quantity {
          margin-top: $size32;

          .item-quantity {
            font-weight: 500;
            font-size: $size14;
          }

          .item-input-and-price {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .product-price {
              font-weight: 500;
              font-size: $size24;
              color: $primary-blue;
            }

            .increaser-decreaser-input {
              display: flex;
              justify-content: space-between;
              border-radius: $element-border-radius;
              border: 1px solid $medium-grey-blue;
              margin-top: $size8;
              padding-left: $size16;
              box-sizing: border-box;
              width: 90px;
              height: $size36;

              input {
                font-size: $size14;
                text-align: left;
                font-weight: normal;
                border: none;
                width: 46px;
              }

              input:focus {
                outline: none;
              }

              .arrows-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: $size16;
                user-select: none;

                svg {
                  width: $size8;
                  height: $size8;
                  cursor: pointer;
                }

                svg:last-of-type {
                  margin-top: $size4;
                  transform: rotateX(180deg);
                }
              }
            }

            .increaser-decreaser-input:focus {
              outline: none;
            }

            @media screen and (max-width: 1238px) and (min-width: 768px) {
              align-items: flex-start;
              flex-direction: column;

              .product-price {
                margin-top: $size8;
              }
            }
          }
        }
        
        .submit-button {
          margin-top: $size24;
          width: 100%;
        }
      }

      .service-summary-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .click-outside-wrapper {
          margin: 0 0 $size24 0;
          border: 1px solid $medium-grey-blue;
          box-shadow: none;
          max-width: 240px;
          box-sizing: border-box;
          cursor: pointer;

          .height {

            div {
              color: $primary-black;
              font-size: $size14;
            }         

            svg {
              width: 12px;
              height: 12px;
              path {
                fill : $primary-black;
              }
            }
  
            .icon-dropdown-open {
              transform: rotate(180deg);
            }

            .text-wrapper {
              text-indent: $size16;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
  
          .options-wrapper {
            margin-top: 4px;
            border: 1px solid $medium-grey-blue;
            max-height: 160px;
            position: relative;
            overflow: overlay;

            .option {
              font-size: $size14;
              line-height: 0px;
              padding: $size16 $size16 $size16 $size16;
              text-indent: 0;
              box-sizing: border-box;
              height: unset;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }

        .service-product-type {
          font-size: $size12;
          color: $dark-grey-blue;
          text-align: left;
          margin-bottom: $size8;
        }

        .service-name {
          font-size: $size24;
          font-weight: 700;
          color: $primary-black;
        }

        .service-subtitle {
          margin-top: $size4;
          font-size: $size14;
          color: $dark-grey-blue;
        }
        
        .service-warning-wrapper {
          display: flex;
          padding: $size16;
          margin-top: $size16;
          width: 100%;
          gap: $size8;
          border-radius: $element-border-radius;
          background: $transparent-blue;
          box-sizing: border-box;
          
          svg {
            flex-shrink: 0;

            path {
              fill: $light-blue-primary;
            }
          }

          .warning-wrapper {
            .title {
              margin-top: 2px;
              font-weight: 700;
              font-size: $size14;
            }

            .warning-description {
              margin-top: $size16;
              line-height: 22px;
              color: $black;
              font-size: $size14;
            }

            .warning-actions {
              display: flex;
              gap: $size16;
              margin-top: $size16;
  
              .action-button {
                background-color: $transparent-blue;
              }
  
              a {
                align-self: center;
                color: $primary-black;
              }

              @media screen and (max-width: 768px) {
                flex-direction: column;
                
                a {
                  align-self: unset;
                }
              }
            }
          }
        }

        .service-plans {
          display: flex;
          flex-direction: column;
          margin-top: $size16;

          .service-plans-title {
            font-size: $size14;
            font-weight: 500;
            margin-bottom: $size8;
          }

          .service-plan-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            border: 1px solid $medium-grey-blue;
            border-radius: $element-border-radius;
            background: $white;
            padding: $size16;
            margin-bottom: $size4;
            box-sizing: border-box;
            cursor: pointer;

            .service-plan-radio-row {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              
              .servie-title-button {
                display: flex;
                justify-content: space-between;
                
                .service-title {
                  font-size: $size14;
                  line-height: 20px;
                }
              }
              
              .service-amount {
                margin-top: $size12;
                font-size: $size12;
                font-weight: 500;
                color: $primary-blue;
              }
              
              .service-offer {
                font-size: $size12;
                color: #21A2EE;
                padding: 6px;
                margin-top: $size12;
                background: #F0FAFF 0% 0% no-repeat padding-box;
                border-radius: 4px;
              }
            }
          }

          .selected {
            border: 1px solid $light-blue-primary !important;
          }
        }

        .service-price {
          text-align: right;
          margin-top: $size32;
          font-size: $size24;
          font-weight: 500;
          color: $primary-blue;
        }

        .submit-button {
          margin-top: $size24;
          width: 100%;
        }
        
        .additional-info {
          font-size: $size12;
          color: #758091;
          padding-top: $size16;
          text-align: center;
        }
      }
    }

    @media screen and (max-width: 1238px) {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      .product-details {
        width: 100%;
      }

      .checkout-details {
        width: 100%;
        margin-top: $size16;
        position: inherit;

        .product-summary-wrapper,
        .service-summary-wrapper {
          width: 100%;
        }
      }
    }
  }
}
