@import "../../../../styles/shared.scss";

.hero-section-builder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 10%;
  height: 100vh;
}

.section-builder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  .text {
    width: 100%;
    text-align: center;
  }
}