@import "../../../../styles/shared.scss";

.video-toolbox {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: $size8;

  .input-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: $size4;

    .input-option {
      margin: 0;
      font-weight: 500;
    }
    
    .input-item {
      width: 100%;
      height: $size36;
      border: 1px solid $medium-grey-blue;
      border-radius: $element-border-radius;
      text-indent: $size16;
      outline: none;
      box-sizing: border-box;
    }
  }

  .title {
    color: $primary-black;
    font-weight: 500;
    font-size: 18px;
    margin-top: auto;
  }
}