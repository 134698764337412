@import "../../styles/shared";

.coltene-store-wrapper {

  .coltene-store-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin: $size16 0 $size16 0;

    .dropdown-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 301px;
      gap: $size16;

      .sorting-label {
        font-size: $size14;
        font-weight: 500;
        color: $primary-black;
        text-align: left;
      }

      .click-outside-wrapper {
        border: 1px solid $medium-grey-blue;
        box-shadow: none;
        max-width: 240px;
        box-sizing: border-box;
        cursor: pointer;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }

          svg {
            width: 12px;
            height: 12px;

            path {
              fill: $primary-black;
            }
          }

          .text-wrapper {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 390px;
          position: relative;
          overflow: overlay;

          .option {
            box-sizing: border-box;
            padding: $size4 $size16 $size4 $size16;
            text-indent: 0;
            min-height: $size36;
            height: max-content;
            line-height: unset;
            display: flex;
            align-items: center;
          }

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }
    }
    
    .filter-button-wrapper {
      display: none;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      height: unset;
      padding: $size16 0;
      gap: $size8;
      width: calc(100% - 32px);
      margin: 0 $size16 $size16 $size16;

      .dropdown-area {
        width: 100%;
        justify-content: flex-start;

        .sorting-label {
          flex-shrink: 0;
        }

        .click-outside-wrapper {
          width: 100%;
          max-width: unset;
        }
      }
    }
  }

  .product-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(393px, 1fr));
    grid-gap: $size16;

    .wrapper-item {
      display: flex;
      width: 100%;
      background-color: $white;
      height: 176px;
      padding: $size16;
      border-radius: $element-border-radius;
      gap: $size16;
      box-sizing: border-box;
      cursor: pointer;

      .product-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        flex-shrink: 0;

        img {
          object-fit: cover;
          width: 120px;
          height: 120px;
          background-size: auto;
          background-position: center;
        }

        svg {
          width: 120px;
          height: 120px;
        }

        @media screen and (max-width: 768px) {
          width: 100px;
          height: 100px;

          img {
            width: 100px;
            height: 100px;
          }

          svg {
            width: 100px;
            height: 100px;
          }
        }
      }

      .product-info-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .product-type {
          margin-bottom: $size8;
          font-size: $size12;
          color: $dark-grey-blue;
        }

        .product-name {
          font-weight: 700;
          font-size: $size16;
          color: $primary-black;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .product-description {
          color: $dark-grey-blue;
          margin-top: $size4;
          font-size: $size14;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .product-price-wrapper {
          margin-top: $size16;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          .prices-wrapper {
            display: flex;
            flex-direction: column;
            gap: $size4;

            .product-price {
              font-size: $size14;
              color: $primary-blue;
              font-weight: 500;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

              &.price-sold-out {
                opacity: 40%;
                color: rgba(0, 0, 0, 0.4);
              }

              &.price-discount-line {
                text-decoration: line-through;
              }

              &.product-discount {
                color: #ea3224;
              }
            }
          }

          .btn-add-to-cart {
            display: none;
            height: 36px;
            width: 36px;
            align-items: center;
            justify-content: center;
            background: $light-blue-primary;
            border-radius: 18px;
            flex-shrink: 0;
            cursor: pointer;
  
            @media screen and (max-width: 768px) {
              display: flex;
            }
          }

          .disabled-cart {
            pointer-events: none;
            cursor: default;
            opacity: 0.5;
          }

          @media screen and (max-width: 768px) {
            min-height: $size36;
            margin-top: $size16;
            align-items: flex-start;
          }
        }
      }

      &:hover {
        transform: scale(1.02);

        .product-price-wrapper {
          .btn-add-to-cart {
            display: flex;
          }
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: auto;
      grid-gap: $size8;
    }
  }
  
  .empty-page-wrapper {
    background-color: inherit;
    min-height: 250px;
  }

  .load-more-wrapper {
    margin-top: $size16;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }
}
