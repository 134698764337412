@import '../../styles/shared';

.my-products-wrapper {
  
  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }

  .legend-wrapper {
    margin: $size16 0;

    .svg-wrapper {
      > div {
        display: flex;
        gap: $size32;

        .legend {
          display: flex;
          align-items: center;
          gap: $size4;

          .dashboard-span {
            height: $size16;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 790px;
    border-radius: $element-border-radius;
    background-color: $white;
    box-shadow: 0px 0px 25px #0000000D;
    box-sizing: border-box;

    .products {
      display: flex;
      flex-direction: column;
      gap: $size4;
      width: 33%;
      max-width: 300px;
      border-radius: $element-border-radius 0px 0px $element-border-radius;
      padding: $size16;
      box-sizing: border-box;
      border-right: 1px solid $medium-grey-blue;

      .card {
        display: flex;
        align-items: center;
        height: 80px;
        background-color: $light-grey-blue;
        border-radius: $element-border-radius;
        border: 1px solid transparent;
        cursor: pointer;
        box-sizing: border-box;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size16 0 $size8;
          height: 64px;
          width: 64px;
          background-color: $white;
          border-radius: $element-border-radius;
          flex-shrink: 0;

          img {
            width: 44px;
            height: 44px;
            object-fit: cover;
          }
        }

        .product-wrapper {
          display: block;
          margin-right: $size8;

          .product-name {
            font-size: $size16;
            color: $primary-black;
            font-weight: 700;
            margin: 0;
          }

          .product-serials {
            font-size: $size14;
            color: $dark-grey-blue;
            margin: 0;
          }
        }

        .has-update {
          position: relative;
          margin-left: auto;
          align-self: flex-start;
          top: $size8;
          right: $size8;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: $red;
        }
      }

      .selected-card {
        border: 1px solid $primary-blue;
      }
    }
    
    .serials-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: $element-border-radius;

      .serial-select-title {
        font-size: $size14;
        margin: $size24 $size16 $size8 $size16;
        font-weight: 500;
        color: $primary-black;
      }

      .click-outside-wrapper {
        margin: 0px $size16 $size32 $size16;
        border: 1px solid $medium-grey-blue;
        box-shadow: none;
        max-width: 328px;
        cursor: pointer;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }         

          svg {
            width: 12px;
            height: 12px;
            path {
              fill : $primary-black;
            }
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 390px;
          position: relative;
          overflow: overlay;

          .options-filter {
            width: 100%;
            height: 100%;
            background-color: $white;
            z-index: 1;

            .search {
              background-color: $light-grey-blue;
              border-radius: $element-border-radius;
              margin: $size16 $size16 $size4 $size16 !important;
              box-shadow: none !important;
              z-index: 1;

              .search-icon {
                display: none;
              }
            }
          }

          .option-with-icon {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $size8;
            font-size: $size14;
            color: $primary-black;

            .online-access {
              margin-right: $size16;

              .no-access {
                opacity: 0.2;

                path {
                  fill: $primary-black;
                }
              }
            }
          }

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }

      .activation-box-text {
        display: flex;
        gap: $size8;
        margin: 0 0 $size24;
        padding: $size16;
        border-radius: $element-border-radius;
        background-color: $transparent-blue;
        color: $primary-black;
    
        .icon-wrapper {
          display: flex;
          align-items: flex-start;
        }
    
        .activation-wrapper {
          display: inline;
          flex-direction: column;
          gap: $size8;
          font-size: $size14;
          padding-top: $size4;
    
          .title {
            font-weight: 700;
            padding-bottom: 8px;
          }

          .activation-steps {
            text-decoration: underline;
            cursor: pointer;
            color: #21A2EE;
          }
        }
      }

      .linked-products-wrapper {
        display: flex;
        flex-direction: column;
        gap: $size4;
        width: 100%;
        box-sizing: border-box;
        padding: $size8 $size16 $size16 $size16;

        .linked-product {
          display: flex;
          align-items: center;
          width: 100%;
          height: 40px;
          padding: 0 $size16;
          border-radius: $element-border-radius;
          background-color: $light-grey-blue;
          box-sizing: border-box;

          .product-nickname {
            color: $primary-black;
            font-size: $size14;
            width: 20%;
          }
          
          .product-image {
            width: 32px;
            height: 32px;
            justify-content: center;
            border-radius: 4px;
            flex-shrink: 0;
            margin: 0 16px 0 0;
          }

          .product-serial {
            color: $dark-grey-blue;
            font-size: $size12;
          }
        }

        .with-navigation {
          cursor: pointer;

          .product-serial {
            color: $primary-blue;
          }
        }
      }

      .content-limiter {
        color: $primary-blue;
        margin: 0 $size16 $size32 $size16;
        text-transform: capitalize;
        cursor: pointer;
      }

      .product-details-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 $size16 $size24 $size16;
        box-sizing: border-box;
        
        .product-status-wrapper {
          display: flex;
          align-items: flex-start;
          min-height: 64px;
          margin-bottom: $size16;

          .image-wrapper {
            width: 64px;
            height: 64px;
            justify-content: center;
            background-color: $white;
            border-radius: $element-border-radius;
            flex-shrink: 0;
            margin: 0 $size16 0 0;

            img {
              width: 44px;
              height: 44px;
              object-fit: cover;
            }
          }

          .product-serial-wrapper {
            display: flex;
            flex-direction: column;
            gap: $size4;
            margin: $size12 $size16 0 0;

            .product-serial {
              color: $primary-black;
              font-size: $size14;
            }

            .product-serial-subtitle {

              .nickname-wrapper {
                display: flex;
                gap: $size16;
                font-size: $size16;

                .product-nickname {
                  color: $primary-black;
                  min-height: 20px;
                  font-weight: 700;
                }

                .nickname-actions-wrapper {
                  display: flex;
                  gap: $size8;

                  .delete-nickname {
                    color: $strong-blue;
                    cursor: pointer;
                  }
  
                  .edit-nickname {
                    color: $primary-blue;
                    cursor: pointer;
                  }
                }
              }

              .nickname-add-wrapper {
                color: $primary-blue;
                font-size: $size16;
                cursor: pointer;
              }

              .disabled {
                pointer-events: none;
                opacity: 0.6;
              }
            }
          }

          .product-status {
            display: flex;
            align-items: center;
            gap: $size24;
            margin-left: auto;

            @media screen and (max-width: 1062px){
              align-items: flex-start;
            }

            .product-status-legend {
              display: flex;
              align-items: center;
              gap: $size8;

              .tooltiped-icon-wrapper {
                .tooltip {
                  bottom: 135%;
                }

                .unavailable {
                  opacity: 0.2;

                  path {
                    fill: $primary-black;
                  }
                }
              }

              @media screen and (max-width: 1062px){
                margin-top: $size8;
              }
            }

            .product-status-actions {
              display: flex;
              gap: $size16;

              @media screen and (max-width: 1062px) {
                flex-direction: column;
              }
            }
          }
        }

        .section-title {
          font-size: $size14;
          color: $primary-black;
          font-weight: 500;
          margin: $size24 0 $size16 0;
        }

        .with-extra-information {
          margin-bottom: $size4;
        }

        .product-software-wrapper {
          display: flex;
          margin-bottom: $size24;

          .update-wrapper {
            display: flex;
            width: 100%;
            background-color: $transparent-blue;
            padding: 0 $size16 $size24 $size16;

            .info-wrapper {
              display: flex;
              flex-direction: column;
              font-size: $size14;
              gap: $size4;

              .title-version {
                margin: 0;
                color: $dark-grey-blue;

                .current-version {
                  color: $primary-black;
                }

                .latest-version {
                  color: $primary-blue;
                }
              }

              .submit-button {
                margin-top: $size16;
              }
            }

            .update-dot {
              margin: $size24 0;
              width: 6px;
              height: 6px;
              border-radius: 3px;
              background-color: $red;
              margin-left: auto;
            }
          }
        }

        .product-cycles-wrapper {
          display: flex;
          flex-direction: column;
          border-top: 1px solid $medium-grey-blue;
          margin: 0 0 $size24 0;

          .extra-information {
            margin: 0 0 $size16 0;
            font-size: $size12;
            color: $dark-grey-blue;
          }

          .cycles-wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(MIN(100%/1, MAX(173px, 100%/6)), 1fr));
            grid-gap: $size4;
            margin-bottom: $size16;

            .cycle-item-wrapper {
              display: flex;
              flex-grow: 1;
              height: 24px;
              padding: 0 $size8;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
              cursor: pointer;
              background-color: #26c875;
              border-radius: $element-border-radius;

              .title {
                margin: 0;
                font-size: $size12;
                font-weight: 700;
                color: $white;
              }
              
              .time {
                margin: 0;
                font-size: $size12;
                color: $white;
                opacity: 0.6;
              }
            }

            .cycle-pending {
              // background-color: $dark-grey-blue !important;
              background-color: #26c875 !important;
            }

            .cycle-complete {
              background-color: #26c875 !important;
            }

            .cycle-fault {
              background-color: #e2445c !important;
            }

            .cycle-aborted,
            .cycle-interrupted,
            .door-open,
            .stop-button-pressed {
              background-color: #fdab3d !important;
            }

            .no-cycles-wrapper {
              display: flex;
              align-items: center;
              border-radius: $element-border-radius;

              .disclaimer {
                margin: 0;
                font-size: $size14;
                font-style: italic;
                color: $dark-grey-blue;
              }
            }
          }

          .ic-track-cycles-wrapper {
            grid-template-columns: repeat(auto-fit, minmax(MIN(100% / 1, MAX(250px, 100% / 6)), 1fr));
            .cycle-tracking-completed {
              background-color: $tracking-green;
            }

            .cycle-tracking-interrupted {
              background-color: $tracking-yellow;
            }

            .cycle-tracking-error {
              background-color: $tracking-red;
            }

            .cycle-tracking-in-progress {
              background-color: $tracking-grey;
            }

            .icon-title-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              gap: $size4;
              
              .icon {
                display: flex;
                gap: 5px;
              }
              
              svg {
                flex-shrink: 0;
              }
              
              .title,
              .cycle-num {
                color: $primary-black !important;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin: 0;
                font-size: $size12;
                font-weight: 700;
              }
              
              .cycle-num {
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .cycles-navigation-buttons-wrapper {
            display: flex;
            gap: $size8;
          }
        }

        .product-documents-wrapper {
          display: flex;
          flex-direction: column;
          border-top: 1px solid $medium-grey-blue;
          margin: 0 0 $size24 0;

          .documents-wrapper {
            display: flex;
            flex-direction: column;
            gap: $size4;
            margin-bottom: $size16;

            .document-item-wrapper {
              display: flex;
              height: 40px;
              width: 100%;
              align-items: center;
              box-sizing: border-box;
              gap: $size8;
              cursor: pointer;

              .image-wrapper {
                display: flex;
                width: 40px;
                height: 40px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
              }

              .rounded-image {
                background-color: $light-grey-blue;
                border-radius: 50%;
              }

              .title-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size4;

                .title {
                  margin: 0;
                  font-size: $size14;
                  color: $primary-black;
                }

                .section {
                  margin: 0;
                  font-size: $size12;
                  color: $dark-grey-blue;
                }
              }
              
            }

            .no-documents-wrapper {
              display: flex;
              align-items: center;
              border-radius: $element-border-radius;

              .disclaimer {
                margin: 0;
                font-size: $size14;
                font-style: italic;
                color: $dark-grey-blue;
              }
            }
          }
        }

        .product-videos-wrapper {
          display: flex;
          flex-direction: column;
          border-top: 1px solid $medium-grey-blue;
          margin: 0 0 $size24 0;

          .videos-wrapper {
            display: flex;
            gap: $size24;
            width: 100%;
            margin-bottom: $size16;

            .video-item-wrapper {
              display: flex;
              flex-direction: column;
              width: 100%;
              max-width: 263px;
              border-radius: $element-border-radius;
              cursor: pointer;

              .thumbnail-wrapper {
                position: relative;
                display: flex;
                width: 100%;
                height: 160px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                border-radius: $element-border-radius;

                .thumbnail-icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
      
                  svg{
                    width: $size32;
                    height: $size32;
                  }
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: $element-border-radius;
                }
              }

              .title {
                margin-top: $size16;
                font-weight: 700;
                text-align: left;
                font-size: $size16;
                word-wrap: break-word;
              }
            }

            .no-videos-wrapper {
              display: flex;
              align-items: center;
              border-radius: $element-border-radius;
              width: 100%;

              .disclaimer {
                margin: 0;
                font-size: $size14;
                font-style: italic;
                color: $dark-grey-blue;
              }
            }
          }
        }

        .product-firmware-wrapper {
          display: flex;
          flex-direction: column;
          border-top: 1px solid $medium-grey-blue;

          .firmware-wrapper {
            display: flex;
            flex-direction: column;
            gap: $size24;
            width: 100%;
            margin-bottom: $size16;

            .internal-section-title {
              font-size: $size14;
              font-weight: 500;
              color: $dark-grey-blue;
              margin: 0;
              text-transform: uppercase;
            }

            .instruction-box-text {
              display: flex;
              gap: $size8;
              padding: $size16;
              border-radius: $element-border-radius;
              background-color: $transparent-blue;
              color: $primary-black;
          
              .icon-wrapper {
                display: flex;
                align-items: flex-start;
              }
          
              .instruction-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size8;
                font-size: $size14;
                padding-top: $size4;
          
                .title {
                  font-weight: 700;
                }

                .instructions_firmware_video_link {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }

            .firmware-documents-wrapper {
              margin-bottom: $size16;
              display: flex;
              flex-direction: column;
              gap: $size8;
          
              .firmware-documents {
          
                .name-wrapper {
          
                  .doc-icon {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 2px 3px #00000014;
                    border-radius: 4px;
                    opacity: 1;
                  }
          
                  .file-name {
                    margin-left: $size8;
                    font-size: $size14;
                    color: $primary-black;
                  }
                }
          
                .action-button {
                  margin-left: auto;
                }
              }
            }

            .firmware-executables-wrapper {
              margin-bottom: $size16;
              display: flex;
              flex-direction: column;
              gap: $size24;
          
              .firmware-executable {
                display: flex;
                gap: $size16;
                align-items: center;
          
                .download-document {
                  background: transparent;
                  outline: none;
                  border: none;
                  cursor: pointer;
                  padding: 0;
                }
          
                .doc-icon {
                  background: #FFFFFF 0% 0% no-repeat padding-box;
                  box-shadow: 0px 2px 3px #00000014;
                  border-radius: 4px;
                  opacity: 1;
                }
          
                .file-name {
                  font-size: $size14;
                  color: $primary-black;
                  font-weight: 500;
                }
              }
            }

            .no-firmware-wrapper {
              display: flex;
              align-items: center;
              border-radius: $element-border-radius;
              width: 100%;

              .disclaimer {
                margin: 0;
                font-size: $size14;
                font-style: italic;
                color: $dark-grey-blue;
              }
            }
          }
        }

        .product-related-products-wrapper {
          display: flex;
          flex-direction: column;
          border-top: 1px solid $medium-grey-blue;
          margin: 0 0 $size24 0;

          .related-products-wrapper {
            display: flex;
            gap: $size24;
            width: 100%;
            margin-bottom: $size16;


            .no-related-products-wrapper {
              display: flex;
              align-items: center;
              border-radius: $element-border-radius;
              width: 100%;

              .disclaimer {
                margin: 0;
                font-size: $size14;
                font-style: italic;
                color: $dark-grey-blue;
              }
            }
          }
        }
      }
    }
  }

  .notification-settings {
    .modal {
      padding: 0;
      .close {
        margin: 15px 10px 0 0;
      }
    }
  }

  .documents {
    .modal {
      top: calc((100vh + 69px) / 2);
      min-height: 300px;
      height: 100%;
      padding-top: 0;

      @media screen and (max-width: 768px) {
        top: calc((100vh - 12px) / 2);
      }
      
      .close {
        position: sticky;
        top: -1px;
        z-index: 1;
        background-color: $white;
        padding-top: 15px;
      }

      .products-document-wrapper {
        min-height: 753px;

        @media screen and (max-width: 768px) {
          margin: 0 !important;
          padding: 0 $size16;
        }

        .dashboard-container {
          position: sticky;
          top: 35px;
          z-index: 1;
          background-color: $white;
          padding-bottom: $size16;
          border-bottom: 1px solid $medium-grey-blue;
        }

        .divider {
          display: none;
        }
      }
    }
  }

  .videos {
    .modal {
      top: calc((100vh + 69px) / 2);
      min-height: 300px;
      height: 100%;
      padding-top: 0;

      @media screen and (max-width: 768px) {
        top: calc((100vh - 12px) / 2);
      }

      .close {
        position: sticky;
        top: -1px;
        z-index: 1;
        background-color: $white;
        padding-top: 15px;
      }

      .videos-wrapper {
        margin: 0 20px;

        @media screen and (max-width: 768px) {
          margin: 0 !important;
          padding: 0 20px;
        }

        .dashboard-container {
          position: sticky;
          top: 35px;
          z-index: 1;
          background-color: $white;
          padding-bottom: $size16;
          border-bottom: 1px solid $medium-grey-blue;
          margin-bottom: $size16;
        }

        .divider {
          display: none;
        }

        .videos {
          .cards-wrapper {
            .card {
              .video-thumbnail {
                height: 200px;
              }
            }
          }
        }
        
        .video-modal {

          .iframe-wrapper {
            position: relative;
            overflow: hidden;
            padding-top: 56.25%;
            margin-bottom: 20px;

            .iframe {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              border: none;
            }
          }
        }
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: unset;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 900px) {
    .content {
      .serials-wrapper {
        .product-details-wrapper {
          .product-serial-wrapper {
            .product-serial-subtitle {
              .nickname-wrapper {
                flex-direction: column !important;
                gap: $size8 !important;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .dashboard-content {
      font-size: $size24;
    }

    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }

    .register-product-button {
      margin: 30px $size16 $size24 $size16;
    }

    .legend-wrapper {
      padding: 0 $size16;

      .svg-wrapper {
        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: $size24;
        } 
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: $size4;
      width: 100%;
      height: 100%;
      min-height: 250px;
      padding-top: $size16;
      border: none;
      box-shadow: none;
      background-color: inherit;

      .card {
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        background-color: $white;
        cursor: pointer;
        box-sizing: border-box;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size16 0 $size8;
          height: 64px;
          width: 64px;
          background-color: $white;
          border-radius: $element-border-radius;
          flex-shrink: 0;

          img {
            width: 44px;
            height: 44px;
            object-fit: cover;
          }
        }

        .product-wrapper {
          display: block;
          margin-right: $size8;

          .product-name {
            font-size: $size16;
            color: $primary-black;
            font-weight: 700;
            margin: 0;
          }

          .product-serials {
            font-size: $size14;
            color: $dark-grey-blue;
            margin: 0;
          }
        }

        .has-update {
          position: relative;
          margin-left: auto;
          align-self: flex-start;
          top: $size8;
          right: $size8;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: $red;
        }
      }
    }
  }
}