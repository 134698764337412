@import "../../../../styles/shared.scss";

.section-toolbox {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: $size8;

  .input-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: $size4;

    .input-option {
      margin: 0;
      font-weight: 500;
    }
    
    .input-item {
      width: 100%;
      height: $size36;
      border: 1px solid $medium-grey-blue;
      border-radius: $element-border-radius;
      text-indent: $size16;
      outline: none;
      box-sizing: border-box;
    }

    .text-area-item {
      width: 100%;
      height: 72px;
      border: 1px solid $medium-grey-blue;
      border-radius: $element-border-radius;
      font-size: $size14;
      padding: $size8;
      line-height: 20px;
      box-sizing: border-box;
      outline: none;
      font-family: "Roboto";
    }

    .row-wrapper {
      display: flex;
      gap: $size8;

      .option {
        display: flex;
        flex-direction: column;
        gap: $size4;
      }
    }
    
    .with-margin-top {
      margin-top: $size8;
    }

    .with-margin-bottom {
      margin-bottom: $size16;
    }
  }

  .title {
    color: $primary-black;
    font-weight: 500;
    font-size: 18px;
    margin-top: auto;
  }

  .child-item {
    display: flex;
    height: $size36;
    align-items: center;

    .arrow-up {
      margin: 0 $size16 0 $size8;
      width: 14px;
      height: 13px;
      transform: rotate(90deg);
      cursor: pointer;

      path {
        fill: $black;
      }
    }

    .arrow-down {
      width: 14px;
      height: 13px;
      transform: rotate(270deg);
      cursor: pointer;

      path {
        fill: $black;
      }
    }

    .disabled-shift {
      cursor: default;
      pointer-events: none;

      path {
        opacity: 0.5;
      }
    }
  }
}