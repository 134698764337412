@import "../../styles/shared";

.dashboard-wrapper {
  min-height: 80vh;

  .text-decoration-none {
    text-decoration: none;
  }

  .no-access-dashboard {
    text-align: center;
  }

  .no-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;

    h1 {
      font-size: 45px;
    }

    .register-btn {
      width: 400px;
    }
  }

  // Legend
  .legend-wrapper {
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0 1rem 0;

    .svg-wrapper {
      @include display-flex;
      justify-content: space-between;
      text-align: left;
      font: normal normal normal 14px/19px Roboto;
      letter-spacing: 0px;
      color: #11232e;
      opacity: 1;

      > div {
        margin-left: 0px;
      }
    }
    .legend {
      display: inline-flex;
      margin-right: 23px;
    }
    .dashboard-span {
      vertical-align: top;
      margin-right: 7px;
    }
    .filter {
      @include display-flex;
      justify-content: flex-end;
      cursor: pointer;
      .grid {
        display: flex;
        .active {
          background-color: #fff;
          padding: 7px;
          border-radius: $element-border-radius;
          box-shadow: 0 1px 1px #00000021;
        }
        .not-active {
          padding: 7px;
        }
      }
    }
    .filter-icon {
      path.a {
        fill: $primary-black;
      }
    }
  }

  // Index

  .filter-mobile {
    display: none;
  }

  .table-view {
    padding: 10px;
    overflow-x: auto;
  }

  // Activate-All
  .activate-container {
    display: flex;
    .activate-dots-sn {
      display: flex;
      align-items: center;
    }
  }

  //List-View
  .list-mobile {
    display: none;
  }
  .list-view {
    display: grid;

    table {
      border-collapse: separate;
      border-spacing: 0px 10px;
      text-align: center;
    }
    tr {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: $element-border-radius;
      opacity: 1;
      box-shadow: 0px 5px 10px #f5f6fa;
    }
    thead {
      box-shadow: 0px 5px 10px #f5f6fa;
      .thead-tr {
        background: #f5f6fa;
      }
    }
    th {
      text-align: left;
      font: normal normal bold 12px/15px Helvetica Neue;
      letter-spacing: 0px;
      color: #768092;
      text-transform: uppercase;
      opacity: 1;
    }
    td {
      padding: 4px 0px;
      border: solid 1px #fff;
      text-align: left;
      font: normal normal normal 14px/16px Helvetica Neue;
      letter-spacing: 0px;
      color: #11232e;
      opacity: 1;
    }
    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .list-img {
      background: transparent 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 1;
      padding-left: 20px;
    }
    .list-table-icons {
      margin-right: 10px;
    }

    .model-name,
    .nick-name,
    .serial-no,
    .other-text,
    .view {
      text-align: left;
      font: normal normal bold 16px/21px Roboto;
      letter-spacing: 0px;
      text-decoration: none;
      color: #11232e;
      opacity: 1;
    }
    .nick-name {
      font: normal normal normal 14px/19px Roboto;
    }
    .serial-no {
      font: normal normal normal 16px/21px Roboto;
      cursor: pointer;
    }
    .other-text {
      cursor: pointer;
      font: normal normal normal 14px/16px Helvetica Neue;
      > div {
        display: flex;
        img {
          margin-right: 10px;
        }
      }
    }
    .view {
      // text-decoration: underline;
      font: normal normal normal 14px/16px Helvetica Neue;
      cursor: pointer;
    }
    .model-text {
      display: inline-block;
      vertical-align: middle;
    }
    .model-img {
      display: inline-flex;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  // Warranty Modal Header
  .dashboard-container {
    @include display-flex;
    justify-content: space-between;
    align-items: center;
    width: 95vw;
    margin: 1rem 0 1.7rem 0;

    .btn {
      margin: 0;
      padding: 0.5rem 2rem;
      font-size: 1rem;
    }
    .dashboard-content {
      font-size: 1.7rem;
    }

    .modal-head {
      display: flex;
      align-items: center;
    }

    .modal-pic {
      .modal-img {
        width: 84px;
        height: 79px;
        margin: 0px;
        padding: 0px;
      }
    }
    .modal-title {
      flex: 1;
      padding-left: 15px;
      .modal-name {
        text-align: left;
        font: normal normal bold 20px/24px Roboto;
        letter-spacing: 0px;
        color: #192c49;
        opacity: 1;
      }
      .modal-sn {
        text-align: left;
        font: normal normal normal 16px/20px Roboto;
        letter-spacing: 0px;
        color: #192c49;
        opacity: 1;
      }
    }
  }

  .dentist-dashboard-wrapper,
  .dealer-technician-dashboard-wrapper,
  .coltene-user-dashboard-wrapper {
    min-height: 80vh;
    width: 100%;
    background-image: url(../../icons/coltene_logo_background.svg), url(../../icons/coltene_logo_background.svg);
    background-repeat: no-repeat;
    background-position: left top, right bottom;
    background-size: 90%, 25%;

    .breadcrumb {
      margin-top: $size8;
    }

    .dashboard-title {
      margin-top: $size16;
      color: $primary-black;

      h1 {
        margin: 0;
      }

      div {
        font-size: $size16;
      }

      @media all and (min-width: 769px) {
        div {
          align-self: flex-end;
        }
      }

      @media all and (max-width: 768px) {
        padding: 0 $size16;

        div {
          margin-top: $size16;
        }
      }
    }

    .dashboard-content {
      margin-top: $size16;

      .links-wrapper {
        width: calc(70% - 16px);
        padding: $size24;
        flex-wrap: wrap;
        gap: $size16;
        box-sizing: border-box;
        border-radius: $size4;
        background-color: $white;

        .link-card {
          position: relative;
          box-sizing: border-box;
          height: 220px;
          padding: $size24;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          background: linear-gradient(128deg, #418fcc, #50b0e7);
          background-position: right;
          border-radius: $element-border-radius;

          svg {
            path {
              fill: $white;
            }
          }

          .card-background {
            box-sizing: border-box;
            position: absolute;
            opacity: 0.1;
            right: 0;
            top: 0;
            width: 60%;
            height: 70%;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .card-image {
            svg {
              width: 80px;
              height: 80px;
            }
          }

          .card-details {
            margin-top: $size20;
            border-left: 4px solid rgba(255, 255, 255, 0.1);
            padding-left: $size16;
            color: $white;
            max-height: 92px;
            div:first-of-type {
              font-size: $size20;
              font-weight: 500;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            div:last-of-type {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          &:hover {
            transform: scale(1.01);
            cursor: pointer;
          }
        }
        @media all and (max-width: 768px) {
          border-radius: 0;
          width: 100%;
          padding: $size16 $size16;
          background-color: $white;
          gap: $size24;
          .link-card {
            width: 100%;
            height: 160px;

            .card-details {
              div:last-of-type {
                -webkit-line-clamp: 2;
              }
            }
            .card-image {
              svg {
                height: 48px;
                width: 48px;
              }
            }

            .card-details {
              margin-top: $size16;
              border-left: 5px solid #009ee7;
              padding-left: $size16;
              color: $white;
              div:first-of-type {
                font-size: $size20;
                font-weight: 500;
              }
            }
          }
        }
      }

      .products-wrapper {
        display: flex;
        flex-direction: column;
        height: 504px;
        width: 30%;
        box-sizing: border-box;
        border-radius: $element-border-radius;
        background-color: $white;

        .products-title {
          display: flex;
          flex-shrink: 0;
          height: 64px;
          padding: 0 $size24;
          font-size: $size20;
          font-weight: 500;
          align-items: center;
        }

        .products-list {
          max-height: calc(100% - 132px);
          min-height: 80px;
          padding: $size16;
          box-sizing: border-box;
          gap: $size16;
          overflow-y: auto;

          .product-card {
            width: 100%;
            height: fit-content;
            gap: $size8;
            margin: 0;
            .left {
              margin: $size8;
              background-color: inherit;
              flex: 1 1;
              align-items: center;
              .mob-grid-img {
                display: flex;
                width: fit-content;
                height: fit-content;

                img {
                  width: 60px;
                  height: 60px;
                  position: initial;
                  top: 0;
                  left: 0;
                }
              }
              .mob-grid-name {
                margin-left: $size16;
              }
            }
          }

          .new-product-card {
            display: flex;
            gap: $size16;
            width: 100%;
            height: fit-content;
            box-sizing: border-box;

            .product-name {
              font-size: $size16;
              font-weight: 500;
            }

            .left {
              display: flex;
              width: 80px;
              height: 80px;

              .img {
                width: 80px;
                height: 80px;
                object-fit: cover;
                flex-shrink: 0;
              }
            }

            .right {
              display: flex;
              flex-direction: column;
              gap: $size8;
              word-break: break-all;

              .products {
                font-size: $size14;
                color: $dark-grey-blue;

                .product-route {
                  font-weight: 500;
                  color: $black;
                }
              }

              .more-products {
                font-size: $size14;
                color: $primary-blue;
                text-transform: capitalize;
                text-decoration: none;
                width: max-content;
              }
            }

            @media all and (min-width: 481px) {
              gap: $size16;
            }
          }

          .empty-page-wrapper {
            min-height: unset;
            justify-content: unset;
            color: $dark-grey-blue;
            .title {
              font-size: $size14;
              line-height: $size18;
              font-weight: normal;
              margin: 0;
            }
          }
        }

        .products-actions {
          display: flex;
          margin-top: auto;
          gap: $size8;
          background-color: $white;
          border-top: 2px solid $medium-grey-blue;
          padding: $size16;
          box-sizing: border-box;
        }

        @media all and(max-width:768px) {
          border-radius: 0;
          width: 100%;
          margin-bottom: $size24;
          height: fit-content;
          max-height: 504px;

          .products-list {
            max-height: calc(100% - 114px);
          }

          .products-actions {
            flex-direction: column;
            height: unset;

            .simple-button {
              width: 100%;
            }
          }
        }
      }
      
      .documents-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 504px;
        width: 30%;
        box-sizing: border-box;
        border-radius: $element-border-radius;
        background-color: $white;

        .documents-title {
          padding-left: $size16;
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: $white;
          height: $size48;
          font-size: $size16;
          font-weight: 500;
        }

        .documents-list {
          height: calc(100% - 116px);
          overflow-y: auto;

          .document-card {
            padding: $size16 0;
            margin: 0 $size16;
            gap: $size8;
            border-bottom: 1px solid $medium-grey-blue;
            cursor: pointer;
            box-sizing: border-box;

            .svg-wrapper {
              display: flex;
              width: $size32;
              height: $size32;
              justify-content: center;
              align-items: center;
              background-color: $light-grey-blue;
              border-radius: 50%;
            }

            .without-background {
              background-color: transparent;
            }

            svg {
              display: block;
            }

            .document-details {
              width: calc(100% - 56px);

              .date {
                color: $dark-grey-blue;
                font-size: $size12;
                gap: $size16;
              }

              .document-title {
                margin-top: $size8;
                font-size: $size14;
                font-weight: 500;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }

          .last-document {
            border-bottom: 1px solid transparent !important;
          }
        }

        .buttons-wrapper {
          display: flex;
          align-items: center;
          height: 68px;
          margin-top: auto;
        }

        .documents-spinner-wrapper {
          position: absolute;
          z-index: 9999;
          width: calc(100% + 8px);
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;
          .loading-spinner {
            left: calc(50% - 1.5em);
          }
        }

        .hide-scrollbar {
          overflow-y: hidden;
        }

        @media all and(max-width:768px) {
          width: 100%;
          margin-bottom: $size24;
          border-radius: 0;
        }
      }
      
      .large-dashboard {
        height: auto;
      }

      .with-order-1 {
        order: 0;
      }

      .with-order-2 {
        order: 1;
      }

      @media all and (max-width: 768px) {
        margin-top: $size40;
      }
    }

    .spinner-wrapper {
      position: fixed;
      z-index: 9999;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;

      .loading-spinner {
        position: relative;
        top: 25%;
        left: 50%;
        transform: translateY(-50%);
      }
    }

    @media all and (max-width: 768px) {
      background-image: none;
    }
  }

  .dentist-dashboard-wrapper {
    .links-wrapper {
      .link-card {
        width: calc(calc(100% - 16px) / 2);
      }
    }
  }

  .dealer-technician-dashboard-wrapper {
    .dashboard-title {
      margin-bottom: $size32;
    }

    .search-product-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      gap: $size8;
      box-sizing: border-box;

      .search-with-filter-wrapper {
        .filter-button-wrapper {
          display: none;
        }
      }

      .submit-button {
        background-color: #009fe3;
        color: $white;
      }

      .submit-button:disabled {
        background-color: #98d8f3;
        opacity: 1;
      }
    }

    .links-wrapper {
      .link-card {
        // width: calc(calc(100% - 32px) / 3);
        width: calc(calc(100% - 16px)/2);
        
      }
    }

    .empty-results {
      min-height: 240px !important;
    }

    .videos-content {
      margin-top: $size16;
      padding: $size24;
      background-color: $white;
      border-radius: $element-border-radius;

      .section-title {
        font-size: $size24;
        font-weight: 700;
        color: $primary-black;
        margin: 0 0 $size24 0;
      }

      .videos-wrapper {
        display: flex;
        gap: $size24;
        width: 100%;
        margin-bottom: $size24;
        box-sizing: border-box;

        .video-item-wrapper {
          display: flex;
          flex-direction: column;
          width: 25%;
          box-sizing: border-box;
          cursor: pointer;

          .thumbnail-wrapper {
            display: flex;
            position: relative;
            width: 100%;
            height: 199px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: $element-border-radius;

            .thumbnail-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);

              svg{
                width: $size32;
                height: $size32;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .title {
            margin: $size16 0 0 0;
            font-weight: 700;
            text-align: left;
            font-size: $size16;
            word-wrap: break-word;
          }
        }
      }
    }

    @media all and (max-width: 768px) {
      .dashboard-content {
        margin-top: $size16;
      }
      
      .search-product-wrapper {
        padding: 0 $size16;

        .search-with-filter-wrapper {
          .search-wrapper {
            .search {
              width: 100%;
              margin: 0 !important;
            }
          }
        }
        .simple-button {
          white-space: nowrap;
          width: 140px;
        }
      }

      .videos-content {
        .videos-wrapper {
          flex-direction: column;
          
          .video-item-wrapper {
            width: 100%;
          }
        }
      }
    }
  }

  .coltene-user-dashboard-wrapper {
    .dashboard-title {
      margin-bottom: $size32;
    }

    .search-product-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      gap: $size8;
      box-sizing: border-box;

      .search-with-filter-wrapper {
        .filter-button-wrapper {
          display: none;
        }
      }

      .submit-button {
        background-color: #009fe3;
        color: $white;
      }

      .submit-button:disabled {
        background-color: #98d8f3;
        opacity: 1;
      }
    }

    .links-wrapper {
      width: 100% !important;
      gap: $size24 !important;

      .link-card {
        width: calc(calc(100% - 48px) / 3);
      }
    }

    .videos-content {
      margin-top: $size16;
      padding: $size24;
      background-color: $white;
      border-radius: $element-border-radius;

      .section-title {
        font-size: $size24;
        font-weight: 700;
        color: $primary-black;
        margin: 0 0 $size24 0;
      }

      .videos-wrapper {
        display: flex;
        gap: $size24;
        width: 100%;
        margin-bottom: $size24;
        box-sizing: border-box;

        .video-item-wrapper {
          display: flex;
          flex-direction: column;
          width: 25%;
          box-sizing: border-box;
          cursor: pointer;

          .thumbnail-wrapper {
            display: flex;
            position: relative;
            width: 100%;
            height: 199px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: $element-border-radius;

            .thumbnail-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);

              svg{
                width: $size32;
                height: $size32;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .title {
            margin: $size16 0 0 0;
            font-weight: 700;
            text-align: left;
            font-size: $size16;
            word-wrap: break-word;
          }
        }
      }
    }

    @media all and (max-width: 768px) {
      .dashboard-content {
        margin-top: $size16;
      }

      .search-product-wrapper {
        padding: 0 $size16;

        .search-with-filter-wrapper {
          .search-wrapper {
            .search {
              width: 100%;
              margin: 0 !important;
            }
          }
        }
        .simple-button {
          white-space: nowrap;
          width: 140px;
        }
      }

      .videos-content {
        border-radius: 0;

        .videos-wrapper {
          flex-direction: column;
          
          .video-item-wrapper {
            width: 100%;
          }
        }
      }
    }
  }

  @media all and (max-width: 480px) {
    .dashboard-container {
      h1 {
        font-size: 16 px;
      }
    }
    .dots-all {
      display: none;
    }

    .product-card {
      margin: 10px;
      h3 {
        display: none;
      }
      @include flex-direction-column;
      // max-width: 353px;
      // width: 100%;
      width: 353px;
      max-width: 90vw;

      .left {
        background-color: #ffffff;
        display: flex;
      }
      .mob-grid-img {
        display: flex;
        align-items: center;
      }
      .mob-grid-name {
        display: flex;
        align-items: center;
        text-align: left;
        font: normal normal bold 16px/24px Roboto;
        letter-spacing: 0px;
        color: #11232e;
        opacity: 1;
      }
      .img {
        position: relative !important;
      }
    }
  }

  @media all and (max-width: 700px) {
    .list-mobile {
      display: block;
      .list-model-header {
        display: flex;
        justify-content: space-between;
        .list-model-name {
          display: flex;
          font: normal normal bold 18px/24px Roboto;
          letter-spacing: 0px;
          color: #11232e;
          opacity: 1;
          align-items: center;
          padding-left: 10px;
          img {
            max-width: 100%;
            padding: 10px;
          }
        }
      }
      .list-serial-number {
        display: flex;
        // text-decoration: underline;
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #11232e;
        opacity: 1;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        // max-width: 353px;
        height: 50px;
        margin: 10px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 1px #0000002f;
        border-radius: $element-border-radius;
        opacity: 1;
      }
      .list-mobile-icons {
        display: flex;
        img {
          margin-right: 15px;
        }
        .list-mobile-caret {
          margin-right: 15px;
          path {
            fill: $primary-black;
          }
        }
      }
    }
    .legend-wrapper {
      .filter {
        display: none;
      }
    }
    .list-view {
      display: none;
    }
  }
  
  .contact-link {
    cursor: pointer;
    color: $primary-blue;
    font-size: 1rem;
    font-weight: 400;
    line-height: 21px;
    
    @media all and (max-width: 768px) {
      font-size: 0.75rem;
    }
  }

  @media all and (max-width: 480px) {
    .product-card {
      .left {
        .mob-grid-img {
          width: 116px;
        }
        .img {
          top: 0px;
        }
      }
      .right {
        .right-child {
          padding: 0px 10px;
        }
      }
    }
    .filter-mobile {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      text-align: left;
      font: normal normal normal 12px/14px Roboto;
      letter-spacing: 0px;
      color: #11232e;
      opacity: 1;
      padding: 10px 10px 20px 10px;
      border-top: 1px solid #768092;
      border-bottom: 1px solid #768092;

      .grid {
        margin-right: 10px;
      }
    }
    .filter {
      display: none;

      div {
        color: #11232e;
        font-weight: bold;
        margin-left: 20px;
      }
    }
  }
}
