@import "../../styles/shared";

.shipping-address-modal {

  .page-modal {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;

    .page-header {
      display: none !important;
    }
  
    .page-content {
      padding: 0 !important;
      height: 100% !important;
      display: flex;
      flex-direction: column;

      .checkout-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        max-width: 1600px;
        width: 95vw;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          margin: 0 auto;
        }

        @media screen and (min-height: 912px) {
          height: 100%;
        }

        .left-side-wrapper {
          position: relative;
          min-height: 100%;
          background: #f5f6fa;
          width: 40%;
      
          @media screen and (max-width: 768px) {
            width: 100%;
            height: fit-content;
            min-height: unset;
          }
      
          .left-side-container {
            padding: 40px;
            background: #f5f6fa;
      
            .company-logo-checkout {
              width: 160px;
              margin-bottom: 30px;
            }

            .checkout-basket-title {
              display: flex;
          
              .title-basket {
                position: relative;
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 100%;
                display: flex;
                align-items: center;
                color: #000000;
              }
            }

            .checkout-products-container::-webkit-scrollbar {
              display: none;
            }
          
            .checkout-products-container {
              margin: 20px 0;
          
              .checkout-product {
                padding: 20px 0;
          
                .item-container {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 20px;
          
                  .checkout-image-wrapper {
                    position: relative;
          
                    .checkout-product-image {
                      border: 1px solid $medium-grey-blue;
                      width: 100px;
                      min-width: 100px;
                      height: 100px;
                      border-radius: 20px;
                      overflow: hidden;
          
                      img,
                      svg {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                      }
                    }
          
                    .product-quantity {
                      position: absolute;
                      width: 30px;
                      height: 30px;
                      top: -15px;
                      right: 0px;
                      background: #04a0e9;
                      border-radius: 15px;
                      text-align: center;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-weight: 500;
                      font-size: 14px;
                      color: #ffffff;
                    }
                  }
          
                  .product-details {
                    .checkout-product-name {
                      font-style: normal;
                      font-weight: 700;
                      font-size: 20px;
                      line-height: 24px;
                    }
          
                    .checkout-product-description {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                    }
          
                    .checkout-product-price-wrapper {
                      padding-top: 10px;
          
                      .checkout-product-price {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: #00000066;
                        text-align: left;
                      }
                    }
                  }
                }
              }
            }

            .checkout-footer-wrapper {
              .divider-line {
                width: 100%;
                height: 2px;
                background: rgba(17, 35, 46, 0.1);
                margin: 20px 0;
              }
          
              .checkout-footer-line {
                display: flex;
                justify-content: space-between;
          
                .footer-line {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 200%;
                  color: rgba(0, 0, 0, 0.6);
                }
          
                .footer-line-right {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 200%;
                  color: rgba(0, 0, 0, 0.6);
                }
              }
          
              .checkout-footer-total {
                display: flex;
                justify-content: space-between;
                font-size: $size20;
                gap: $size8;
          
                .total-line {
                  font-weight: 500;
                  color: #000000;
                }
          
                .total-line-right {
                  font-weight: 700;
                  color: #000000;
                }
                
                @media screen and (max-width: 1489px) {
                  font-size: $size24;
                }
                
                @media screen and (max-width: 1089px) {
                  font-size: $size16;
                }
              }
            }
      
            @media screen and (max-width: 960px) {
              padding: $size40 $size24;
            }
          }
        }

        .right-side-wrapper {
          height: auto;
          position: relative;
          width: 55%;
      
          @media screen and (max-width: 768px) {
            width: 100%;
          }
      
          .right-side-container {
            padding-top: 40px;
      
            .shipping-title {
              font-weight: 700;
              font-size: 24px;
              line-height: 100%;
              margin-bottom: 40px;
            }
      
            .shipping-address-form-container {
              .select-col-wrapper {
                margin: 0 0 16px;
                max-width: 100%;
                background: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 10px;
              }
      
              .input-col-wrapper {
                .input-box {
                  box-shadow: none;
                  background: #ffffff;
                  border-radius: 10px;
      
                  input {
                    border: 1px solid rgba(0, 0, 0, 0.1);
      
                    &:focus {
                      border: 1px solid #04a0e9;
                    }
                  }
                }
              }
      
              input {
                border-radius: 10px;
                background: #ffffff;
      
                &.billingSameAsShipping {
                  width: 24px;
                  height: 24px;
                  background: #04a0e9;
                  border-radius: $element-border-radius;
                }
              }
      
              .billing-same-as-shipping {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                margin-left: 10px;
              }
      
              .item-comp-group {
                display: flex;
                width: 100%;
      
                @media screen and (max-width: 768px) {
                  display: block;
                }
              }
      
              .item-comp {
                width: 100%;
      
                .countryCode {
                  pointer-events: none;
                  cursor: default;
                  opacity: 0.5;
                }
      
                .label-input {
                  margin-bottom: 8px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 17px;
                }
      
                &.space-field {
                  margin-right: 20px;
                }
      
                &.address-field {
                  position: relative;
      
                  .google-places-wrapper {
                    position: absolute;
                    background: #fff;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 4px;
                    max-height: 50vh;
                    z-index: 2;
                    width: 100%;
                    top: 70px;
      
                    .google-place {
                      cursor: pointer;
                      text-align: left;
                      padding: 9px 21px;
      
                      &:hover {
                        background-color: $grey-background;
                      }
                    }
                  }
                }
      
                .field-required {
                  color: $red;
                }
              }
      
              .input-col-wrapper {
                max-width: 100%;
                margin-bottom: 20px;
              }
            }
      
            .checkout-btn-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 40px;
              flex-direction: row-reverse;
      
              @media screen and (max-width: 768px) {
                display: block;
              }
      
              .go-shopping-button {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                cursor: pointer;
                padding: 20px 0;
      
                @media screen and (max-width: 768px) {
                  margin: auto;
                  text-align: center;
                }
      
                .arrow-left-icon {
                  margin-right: 10px;
                  height: 12px;
                  width: 18px;
                }
              }
      
              .continue-checkout-button {
                padding: 11px 24px;
                background: #04a0e9;
                border-radius: 10px;
                margin: 16px 0;
      
                @media screen and (max-width: 768px) {
                  max-width: 100%;
                  width: 100%;
                }
              }
            }
      
            @media screen and (max-width: 960px) {
              padding: $size40 $size24 0 $size24;
            }
          }
        }
      }

      .with-full-height {
        height: 100% !important;

        @media screen and (max-width: 768px) {
          height: unset !important;
        }
      }
    }

    .page-footer {
      display: none !important;
    }
  }
}