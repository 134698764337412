@import '../../styles/shared';

.status-legend {
  margin-top: 12px;
  width: 100%;
  // justify-content: space-between;

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    width: 100%;

    li {
      @include display-flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-right: 4%;
      }

      @media screen and (max-width: 480px) {
        margin-top: 2px;
      }
    }

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  .icon,
  .status-icon {
    margin-right: 6px;

    path {
      stroke: $dark-grey-blue;
      fill: $dark-grey-blue;

      &:nth-of-type(2) {
        fill: $dark-grey-blue;
      }
    }

    &.update-icon {
      path {
        &:nth-of-type(1) {
          fill: #fff;
        }
      }

      circle {
        fill: transparent;
        cx: 0;
        cy: 0;
        r: 0;
      }

      &:not(.active) {
        position: relative;
        left: -7px;
        margin-right: 0;
      }
    }

    &.active {
      path {
        // fill: $primary-blue;
        stroke: $primary-blue;

        &:nth-of-type(2) {
          fill: $dark-grey-blue;
        }
      }
    }
  }

  > div:not(:last-of-type) {
    margin-right: 4.5%;
  }

  .status-icon {
    border-radius: 50%;
    height: 12px;
    width: 12px;

    &.success-icon {
      background: $green;
    }

    &.error-icon {
      background: $red;
    }

    &.warning-icon {
      background: $orange;
    }

    &.pending-icon {
      background: $dark-grey-blue;
    }
  }
}