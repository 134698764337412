@import '../../styles/shared';

.cycle-fault-predictions-modal-wrapper {
  .page-modal-wrapper {
    .page-modal {
      height: max-content !important;
      max-width: 600px !important;
      overflow: unset;

      .page-content {
        padding: 0 $size24 $size24 $size24 !important;
        height: max-content !important;
        display: flex;
        flex-direction: column;
        overflow: unset;

        .title {
          font-size: $size18;
          color: $primary-black;
          font-weight: 500;
          margin-bottom: $size8;
        }

        .subtitle {
          font-size: $size14;
          font-weight: 500;
          color: $primary-black;
          margin-bottom: $size4;
        }

        .feedback-input {
          width: 100%;
          height: $size36;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          text-indent: $size16;
          outline: none;
          box-sizing: border-box;
          margin-bottom: $size16;
          flex-shrink: 0;
        }

        .fault-disclaimer {
          border-radius: $element-border-radius;

          .rating-wrapper {
            display: flex;
            padding: $size8 0;
            justify-content: space-between;
            align-items: center;

            .rating-buttons {
              display: flex;
              gap: $size16;
              align-items: center;
              margin-left: auto;

              svg {
                height: $size18;
                width: $size18;
                cursor: pointer;
              }

              .selected-option {
                fill: $primary-blue !important;
              }

              .thumb-down {
                transform: rotate(180deg);
              }
            }
          }

          .edit-prediction {
            background: transparent;
            border: none;
            color: $primary-blue;
            margin-left: auto;
            cursor: pointer;
          }
        }

        .fault-feedback {
          padding: $size8 $size16;
          background-color: $transparent-blue;
        }

        .fault-prediction-list {
          margin: $size16 0 $size16 $size16;
          padding: 0;
          list-style: none;

          .fault-prediction {
            display: flex;
            align-items: center;
            font-size: $size14; 
            color: $primary-black;
            height: $size36;

            .probability {
              margin-left: auto;
            }
          }

          li::before {
            content: "\2022";
            color: $primary-blue;
            font-weight: bold;
            font-size: $size16;
            display: inline-block;
            width: 12px;
            margin-left: -16px;
          }
        }

        .feedback-dropdown {
          margin-bottom: $size16;
        }

        .disabled-dropdown {
          pointer-events: none;

          button {
            color: $disabled-grey;
            background-color: $white;
          }

          svg {
            opacity: 0.5;
          }
        }

        .disabled-input {
          pointer-events: none;
          color: $disabled-grey;
          background-color: $white;
        }

        .feedback-buttons-wrapper {
          display: flex;
          justify-content: flex-end;
          gap: $size8;
          margin-top: $size8;
        }

        .with-margin-top {
          margin-top: $size16;
        }
      }

      .page-footer {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal-wrapper {
      .page-modal {
        height: 100% !important;
        width: 100% !important;
        overflow: auto;

        .page-content {
          height: 100% !important;
          padding: $size16 !important;
          overflow: auto;

          .feedback-buttons-wrapper {
            display: flex;
            justify-content: space-between;
            gap: $size8;
            margin-top: auto;

            .simple-button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}