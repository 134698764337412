@import '../../../styles/shared';

:root {
  --scale-width: 1;
  --scale-height: 1;
}

@media (min-width: 721px) {
  :root {
    --scale-width: 1.509;
    --scale-height: 1.516;
  }
}

.debug-screen {
  width: 1746px;
  padding-top: 10px;

  @media screen and (max-width: 720px) {
    width: 800px;
  }

  .hydrim-screen-1 {
    background-color: #121417;
    padding: 24px 0px;
    margin: 0 auto;
    width: 100%;
  }

  .debug-screen1 {
    position: relative;
    width: 1150px;
    height: 646px;
    font-size: 18px;
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 6px;

    @media screen and (max-width: 720px) {
      width: 762px;
      height: 426px;
      font-size: 16px;
    }

    .debug-name {
      position: absolute;
      top: calc(56px * var(--scale-height));
      left: calc(38px * var(--scale-width));
      width: calc(120px * var(--scale-width));
      color: white;
      font-weight: bold;
      font-size: 18px;

      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
    }

    .primary-phase {
      position: absolute;
      top: calc(119px * var(--scale-height));
      left: calc(38px * var(--scale-width));
      width: calc(120px * var(--scale-width));
      color: white;
      font-size: 18px;
      font-weight: bold;

      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
    }

    .action-state {
      position: absolute;
      top: calc(147px * var(--scale-height));
      left: calc(38px * var(--scale-width));
      width: calc(120px * var(--scale-width));
      color: white;
      font-size: 18px;

      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
    }

    .timing-info {
      position: absolute;
      top: calc(202px * var(--scale-height));
      left: calc(46px * var(--scale-width));
    }

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
    }

    .icon {
      width: calc(23px * var(--scale-width));
      height: calc(23px * var(--scale-height));
    }

    .time-text {
      margin-left: calc(11px * var(--scale-width));
      color: #ffffff;
      font-size: 16px;
    }

    .lcd-text {
      position: absolute;
      top: calc(271px * var(--scale-height));
      left: calc(45px * var(--scale-width));
      color: white;
      font-size: 11px;
      text-transform: uppercase;
    }

    .pcb-text {
      position: absolute;
      top: calc(271px * var(--scale-height));
      left: calc(109px * var(--scale-width));
      color: white;
      font-size: 11px;
      text-transform: uppercase;
    }

    .machine-state-image {
      position: absolute;
      top: calc(287px * var(--scale-height));
      left: calc(37px * var(--scale-width));
      width: calc(107px * var(--scale-width));
      height: calc(136px * var(--scale-height));
    }

    .fan-lcd,
    .fan-pcb {
      position: absolute;
      width: calc(16px * var(--scale-width));
      height: calc(17px * var(--scale-height));

      img {
        width: calc(16px * var(--scale-width));
        height: calc(17px * var(--scale-height));
      }
    }

    .fan-lcd {
      left: calc(50px * var(--scale-width));
      top: calc(293px * var(--scale-height));
    }

    .fan-pcb {
      left: calc(114px * var(--scale-width));
      top: calc(293px * var(--scale-height));
    }

    .door-position {
      position: absolute;
      left: calc(77px * var(--scale-width));
      top: calc(339px * var(--scale-height));
      width: calc(26px * var(--scale-width));
      height: calc(38px * var(--scale-height));

      img {
        width: calc(26px * var(--scale-width));
        height: calc(38px * var(--scale-height));
      }
    }

    .leak-sensor {
      position: absolute;
      left: calc(56px * var(--scale-width));
      top: calc(418px * var(--scale-height));
      width: calc(78px * var(--scale-width));
      height: calc(40px * var(--scale-height));

      img {
        width: calc(78px * var(--scale-width));
        height: calc(40px * var(--scale-height));
      }
    }

    .blower {
      position: absolute;
      left: calc(249px * var(--scale-width));
      top: calc(100px * var(--scale-height));
      width: calc(508px * var(--scale-width));
      height: calc(48px * var(--scale-height));

      img {
        width: calc(508px * var(--scale-width));
        height: calc(48px * var(--scale-height));
      }
    }

    .condenser {
      position: absolute;
      left: calc(282px * var(--scale-width));
      top: calc(113px * var(--scale-height));
      width: calc(91px * var(--scale-width));
      height: calc(33px * var(--scale-height));

      img {
        width: calc(91px * var(--scale-width));
        height: calc(33px * var(--scale-height));
      }
    }

    .hepa-filter {
      position: absolute;
      left: calc(719px * var(--scale-width));
      top: calc(53px * var(--scale-height));
      width: calc(49px * var(--scale-width));
      height: calc(44px * var(--scale-height));

      img {
        width: calc(49px * var(--scale-width));
        height: calc(44px * var(--scale-height));
      }
    }

    .rpm-top-text {
      position: absolute;
      left: calc(408px * var(--scale-width));
      top: calc(121px * var(--scale-height));
      color: white;
      font-size: 22px;
      z-index: 2;
    }

    .rpm-top-text .rpm-label {
      position: absolute;
      left: calc(53px * var(--scale-width));
      top: calc(11px * var(--scale-height));
      color: #fff;
    }

    .rpm-top-text .rpm-value {
      position: absolute;
      left: calc(18px * var(--scale-width));
      top: calc(11px * var(--scale-height));
      color: #fff;
      font-weight: 700;
    }

    .rpm-monitor {
      position: absolute;
      left: calc(521px * var(--scale-width));
      top: calc(130px * var(--scale-height));
      display: flex;
      z-index: 2;

      img {
        width: calc(13px * var(--scale-width));
        height: calc(20px * var(--scale-height));
      }
    }

    .washer-box {
      position: absolute;
      left: calc(396px * var(--scale-width));
      top: calc(118px * var(--scale-height));
      width: calc(257px * var(--scale-width));
      height: calc(218px * var(--scale-height));

      img {
        width: calc(257px * var(--scale-width));
        height: calc(218px * var(--scale-height));
      }
    }

    .washer-arm-top,
    .washer-arm-bottom {
      position: absolute;
      width: calc(159px * var(--scale-width));
      height: calc(39px * var(--scale-height));

      img {
        width: calc(159px * var(--scale-width));
        height: calc(39px * var(--scale-height));
      }
    }

    .washer-arm-top {
      left: calc(417px * var(--scale-width));
      top: calc(161px * var(--scale-height));
    }

    .washer-arm-bottom {
      left: calc(417px * var(--scale-width));
      top: calc(250px * var(--scale-height));
    }

    .rpm-bottom-text {
      position: absolute;
      left: calc(408px * var(--scale-width));
      top: calc(292px * var(--scale-height));
      color: white;
      font-size: 22px;
    }

    .rpm-bottom-text .rpm-label {
      position: absolute;
      left: calc(53px * var(--scale-width));
      top: calc(11px * var(--scale-height));
      color: #fff;
    }

    .rpm-bottom-text .rpm-value {
      position: absolute;
      left: calc(18px * var(--scale-width));
      top: calc(11px * var(--scale-height));
      color: #fff;
      font-weight: 700;
    }

    .rpm-bottom-separator {
      position: absolute;
      left: calc(519px * var(--scale-width));
      top: calc(301px * var(--scale-height));
      width: calc(1px * var(--scale-width));
      height: calc(25px * var(--scale-height));
    }

    .rpm-monitor-bottom {
      position: absolute;
      left: calc(521px * var(--scale-width));
      top: calc(303px * var(--scale-height));
      display: flex;
      gap: 0;

      img {
        width: calc(13px * var(--scale-width));
        height: calc(20px * var(--scale-height));
      }
    }

    .chamber-temperature-img {
      position: absolute;
      left: calc(610px * var(--scale-width));
      top: calc(168px * var(--scale-height));
      width: calc(18px * var(--scale-width));
      height: calc(80px * var(--scale-height));
    }

    .chamber-temperature-text {
      position: absolute;
      right: calc(130px * var(--scale-width));
      top: calc(258px * var(--scale-height));
      color: white;

      .temp-unit {
        font-size: 20px;
        color: #939394;
      }

      @media screen and (max-width: 720px) {
        right: calc(158px * var(--scale-width));

        .temp-unit {
          font-size: 12px;
          color: #939394;
        }
      }
    }

    .water-valves {
      position: absolute;
      left: calc(168px * var(--scale-width));
      top: calc(51px * var(--scale-height));
      width: calc(248px * var(--scale-width));
      height: calc(42px * var(--scale-height));

      img {
        width: calc(248px * var(--scale-width));
        height: calc(42px * var(--scale-height));
      }
    }

    .air-gap-valve-softener {
      position: absolute;
      left: calc(213px * var(--scale-width));
      top: calc(203px * var(--scale-height));
      width: calc(209px * var(--scale-width));
      height: calc(197px * var(--scale-height));

      img {
        width: calc(209px * var(--scale-width));
        height: calc(197px * var(--scale-height));
      }
    }

    .salt-reservoir-switch {
      position: absolute;
      left: calc(164px * var(--scale-width));
      top: calc(313px * var(--scale-height));
      width: calc(49px * var(--scale-width));
      height: calc(62px * var(--scale-height));

      img {
        width: calc(49px * var(--scale-width));
        height: calc(62px * var(--scale-height));
      }
    }

    .salt-reservoir-valve {
      position: absolute;
      left: calc(183px * var(--scale-width));
      top: calc(298px * var(--scale-height));
      width: calc(58px * var(--scale-width));
      height: calc(20px * var(--scale-height));

      img {
        width: calc(58px * var(--scale-width));
        height: calc(20px * var(--scale-height));
      }
    }

    .air-gap-pump {
      position: absolute;
      left: calc(178px * var(--scale-width));
      top: calc(100px * var(--scale-height));
      width: calc(270px * var(--scale-width));
      height: calc(139px * var(--scale-height));

      img {
        width: calc(270px * var(--scale-width));
        height: calc(139px * var(--scale-height));
      }
    }

    .air-gap-switch {
      position: absolute;
      left: calc(409px * var(--scale-width));
      top: calc(44px * var(--scale-height));
      width: calc(202px * var(--scale-width));
      height: calc(69px * var(--scale-height));

      img {
        width: calc(202px * var(--scale-width));
        height: calc(69px * var(--scale-height));
      }
    }

    .ro-water-valve {
      position: absolute;
      left: calc(189px * var(--scale-width));
      top: calc(387px * var(--scale-height));
      width: calc(276px * var(--scale-width));
      height: calc(51px * var(--scale-height));

      img {
        width: calc(276px * var(--scale-width));
        height: calc(51px * var(--scale-height));
      }
    }

    .drain-pump {
      position: absolute;
      left: calc(594px * var(--scale-width));
      top: calc(343px * var(--scale-height));
      width: calc(75px * var(--scale-width));
      height: calc(111px * var(--scale-height));

      img {
        width: calc(75px * var(--scale-width));
        height: calc(111px * var(--scale-height));
      }
    }

    .circulation-pump {
      position: absolute;
      left: calc(270px * var(--scale-width));
      top: calc(161px * var(--scale-height));
      width: calc(149px * var(--scale-width));
      height: calc(223px * var(--scale-height));

      img {
        width: calc(149px * var(--scale-width));
        height: calc(223px * var(--scale-height));
      }
    }

    .air-valve-connection {
      position: absolute;
      left: calc(325px * var(--scale-width));
      top: calc(202px * var(--scale-height));
      width: calc(78px * var(--scale-width));
      height: calc(23px * var(--scale-height));

      img {
        width: calc(78px * var(--scale-width));
        height: calc(23px * var(--scale-height));
      }
    }

    .air-tank {
      position: absolute;
      left: calc(295px * var(--scale-width));
      top: calc(180px * var(--scale-height));
      width: calc(51px * var(--scale-width));
      height: calc(68px * var(--scale-height));

      img {
        width: calc(51px * var(--scale-width));
        height: calc(68px * var(--scale-height));
      }
    }

    .water-connection {
      position: absolute;
      left: calc(282px * var(--scale-width));
      top: calc(236px * var(--scale-height));
      width: calc(121px * var(--scale-width));
      height: calc(27px * var(--scale-height));

      img {
        width: calc(121px * var(--scale-width));
        height: calc(27px * var(--scale-height));
      }
    }

    .lcs-system {
      position: absolute;
      left: calc(402px * var(--scale-width));
      top: calc(203px * var(--scale-height));
      width: calc(53px * var(--scale-width));
      height: calc(53px * var(--scale-height));

      img {
        width: calc(53px * var(--scale-width));
        height: calc(53px * var(--scale-height));
      }
    }

    .sump {
      position: absolute;
      left: calc(409px * var(--scale-width));
      top: calc(329px * var(--scale-height));
      width: calc(202px * var(--scale-width));
      height: calc(76px * var(--scale-height));

      img {
        width: calc(202px * var(--scale-width));
        height: calc(76px * var(--scale-height));
      }
    }

    .sump-heater {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: calc(46px * var(--scale-width));
      height: calc(38px * var(--scale-height));
      transform: translateX(-50%);

      img {
        width: calc(46px * var(--scale-width));
        height: calc(38px * var(--scale-height));
      }
    }

    .rinse-aid-valve {
      position: absolute;
      left: calc(653px * var(--scale-width));
      top: calc(175px * var(--scale-height));
      width: calc(72px * var(--scale-width));
      height: calc(21px * var(--scale-height));

      img {
        width: calc(72px * var(--scale-width));
        height: calc(21px * var(--scale-height));
      }
    }

    .rinse-aid {
      position: absolute;
      left: calc(721px * var(--scale-width));
      top: calc(164px * var(--scale-height));
      width: calc(44px * var(--scale-width));
      height: calc(44px * var(--scale-height));

      img {
        width: calc(44px * var(--scale-width));
        height: calc(44px * var(--scale-height));
      }
    }

    .bellows-pump {
      position: absolute;
      left: calc(653px * var(--scale-width));
      top: calc(239px * var(--scale-height));
      width: calc(75px * var(--scale-width));
      height: calc(31px * var(--scale-height));

      img {
        width: calc(75px * var(--scale-width));
        height: calc(31px * var(--scale-height));
      }
    }

    .bellows-pump-countdown {
      position: absolute;
      left: calc(50% * var(--scale-width));
      bottom: 31px;
      width: calc(36px * var(--scale-width));
      height: calc(20px * var(--scale-height));
      background-color: #121417;
      color: white;
      border: 1px solid white;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      transform: translateX(-50%);
    }

    .dosing-filling-pump {
      position: absolute;
      left: calc(708px * var(--scale-width));
      top: calc(262px * var(--scale-height));
      width: calc(50px * var(--scale-width));
      height: calc(70px * var(--scale-height));

      img {
        width: calc(50px * var(--scale-width));
        height: calc(70px * var(--scale-height));
      }
    }

    .detergent-reservoir {
      position: absolute;
      left: calc(727px * var(--scale-width));
      top: calc(238px * var(--scale-height));
      width: calc(32px * var(--scale-width));
      height: calc(31px * var(--scale-height));

      img {
        width: calc(32px * var(--scale-width));
        height: calc(31px * var(--scale-height));
      }
    }

    .detergent-pouch {
      position: absolute;
      left: calc(681px * var(--scale-width));
      top: calc(296px * var(--scale-height));
      width: calc(33px * var(--scale-width));
      height: calc(37px * var(--scale-height));

      img {
        width: calc(33px * var(--scale-width));
        height: calc(37px * var(--scale-height));
      }
    }
  }

  .debug-screen2 {
    margin: 0 auto;
    width: 100%;

    @media screen and (max-width: 720px) {
      margin: 24px 0;
      width: 762px;

      svg {
        height: 100%;
      }
    }

    svg {
      width: 100%;
    }
  }

  .chart-title {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    padding: 40px;
  }

  .charts {
    width: 100%;
    background-color: #ffffff;

    @media screen and (max-width: 720px) {
      width: 762px;
    }
  }

  .temperature-chart,
  .input-chart,
  .output-chart {
    margin: 0 auto;
    width: 95%;

    .charts-responsive-container {
      height: 100%;
    }
  }

  .temperature-chart {
    height: 600px;

    .temperature-chart-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75%;
      background-color: #e2e6e9;
    }

    @media screen and (max-width: 720px) {
      height: 400px;
    }
  }

  .input-chart {
    height: 600px;

    .input-chart-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75%;
      background-color: #e2e6e9;
    }

    @media screen and (max-width: 720px) {
      height: 400px;

      .legend-container {
        flex-direction: row;
      }
    }
  }

  .output-chart {
    height: 750px;

    .output-chart-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60%;
      background-color: #e2e6e9;
    }

    .legend-container {
      height: 40%;
    }

    @media screen and (max-width: 720px) {
      .legend-container {
        height: 50%;
      }

      .output-chart-wrapper {
        height: 50%;
      }
    }
  }

  .chart-container {
    position: relative;
    background-color: #f5f6fa;
    width: 1170px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }

  .legend-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 25%;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;

    .button-wrapper {
      .simple-button {
        background-color: #00a0e9;
        color: #ffffff;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
      }

      .toggle-button:hover {
        background-color: #2a6a94;
      }
    }

    .legend-items {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 24px;
      max-width: 1660px;

      .legend-item {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .legend-checkbox {
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background-color: white;
        cursor: pointer;
        position: relative;
        display: inline-block;
        appearance: none;
      }

      .legend-checkbox:checked::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      @media screen and (max-width: 720px) {
        max-width: 762px;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;
      }
    }
  }

  .tooltip {
    background-color: $white;
    border-radius: $element-border-radius;
    box-shadow: $modal-box-shadow;
    display: flex;
    gap: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 250px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    pointer-events: all;
    text-align: left;
    width: max-content;
    z-index: 1000;

    .tooltip-item {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .item {
      color: $primary-blue;
      font-weight: bold;
    }

    .legend {
      display: flex;
      gap: 8px;
    }

    p {
      margin: 0;
      font-size: 12px;
    }
  }
}