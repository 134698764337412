@import "../../../styles/shared";

.order-details-wrapper {
  margin-top: $size28;
  padding: $size24;
  border-radius: $element-border-radius;
  height: 100%;
  width: auto;
  background-color: $white;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $size24;

    .back-icon {
      width: $size32;
      height: $size32;

      &:hover {
        cursor: pointer;
      }
    }

    .order-title {
      margin-left: $size14;
      font-size: $size24;
      color: $primary-black;
      text-align: left;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 768px) {
    .header .order-title {
      font-size: $size16;
    }
  }

  .order-details-container {

    .ordered-products-container {

      .ordered-products-title {
        font-size: $size16;
        font-weight: bold;
        text-align: center;
        color: $primary-black;
        text-align: left;
      }

      .table-products {
        margin-top: $size16;

        .table-products-content {

          .table-products-header,

          .table-products-row {
            display: grid;
            grid-template-columns: minmax(220px, 0.6fr)  minmax(50px, 0.6fr) minmax(110px, 0.6fr);
            line-height: $size36;
            text-align: left;
            margin-top: $size16;
          }

          .table-products-header {
            background-color: $table-header-grey;
            font-weight: bold;
            height: $size32;

            .header-products {
              &:nth-of-type(1) {
                padding-left: $size16;
              }

              &:nth-of-type(2),
              &:nth-of-type(3),
              &:nth-of-type(4) {
                padding-left: $size24;
              }
              .header-products-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $size14;
              }
            }
          }

          .table-products-row {
            background-color: $white;

            .row-product-content {
              height: 80px;
              font-size: $size14;

              &:nth-of-type(1) {
                margin-top: $size16;
              }

              &:nth-of-type(6n) {
                padding-left: $size24;
                padding-right: $size16;
              }

              &:nth-of-type(6n-5) {
                padding-left: $size16;
              }

              &:nth-of-type(6n-4),
              &:nth-of-type(6n-3),
              &:nth-of-type(6n-2) {
                padding-left: $size24;
              }

              .row-product-data {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .text-data {
                position: relative;
                top: -$size24;
                width: 80%;
              }

              .total-price {
                color: $primary-blue;
              }

              .order-details-item-wrapper {
                width: inherit;
                display: block;

                .item-details {

                  .details-l {
                    img, svg {
                      width: 80px;
                      height: 80px;
                      border-radius: $element-border-radius;
                    }
                  }

                  .details-r {
                    width: 400px;
                    margin-left: $size16;
                    line-height: 24px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    .item-name {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-size: $size16;
                      font-weight: bold;
                      color: $primary-black;
                    }

                    .item-subtitle,

                    .item-subscription-interval {
                      color: $dark-grey-blue;
                      font-size: $size14;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                    
                    .item-unit-price {
                      color: #00a0e9;
                    }
                  }
                }
              }
            }
          }

          .table-footer {
            margin-top: $size32;
            display: grid;
            grid-template-columns: minmax(220px, 0.6fr) minmax(50px, 0.6fr) minmax(110px, 0.6fr);
            grid-template-rows: repeat(36px, 3);
            overflow: hidden;
            border-top: 1px solid $medium-grey-blue;
            padding: $size32 0 0 0;

            .footer {
              padding-left: $size24;
              height: $size32;
              display: flex;
              align-items: center;
            }

            .footer-text {
              width: auto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .footer-bolded {
              font-weight: 700;
            }
            
            .promotion-footer {
              padding-left: $size16 !important;
            }
          }
        }
      }

      .mobile-view-products {

        .header {

          .order-title {
            font-size: $size16;
          }
        }
  
        .ordered-products-title {
          font-size: $size14;
        }

        .table-products-row {
          gap: $size8;
          margin-top: $size16;
          margin-bottom: $size16;

          .row-product-data {
            margin-bottom: $size16;

            .item-details {
              display: flex;
              gap: $size16;

              .details-l {
                background: $light-grey-blue;
                width: 80px;
                height: 80px;
                border-radius: $element-border-radius;
  
                img {
                  width: 80px;
                  height: 80px;
                  border-radius: $element-border-radius;
                }
  
                svg {
                  width: 80px;
                  height: 80px;
                  border-radius: $element-border-radius;
                }
              }
  
              .details-r {
                font-size: $size14;
  
                .item-name {
                  font-weight: 700;
                }
                
                .item-unit-price {
                  color: #00a0e9;
                }
              }
            }
          }

          .quantity-price-info {
            display: flex;
            flex-direction: column;
            gap: $size8;

            .row-product-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              font-size: $size14;

              .title {
                color: $black;
                font-weight: bold;
              }

              .value {
                color: $black;
                text-align: end;
              }
            }
          }
        }

        .price-overview {
          display: flex;
          flex-direction: column;
          gap: $size8;
          margin-top: $size16;
          border-top: 1px solid $medium-grey-blue;
          padding: $size16 0 0 0;
          width: 100%;

          .price {
            display: flex;
            gap: $size8;
            font-size: $size14;
            color: $primary-black;
            width: 100%;

            .label {
              width: 50%;
              text-align: start;
            }

            .value {
              width: 50%;
              text-align: end;
            }

            .bolded {
              font-weight: 700;
            }
          }
        }

        .payment-details {
        
          .payment-details-container {
            margin-bottom: $size16;

            .payment-details-title {
              font-size: $size14;
            }
  
            .payment-details-wrapper {
              display: flex;
              flex-direction: column;
              gap: $size8;
              margin-bottom: $size16;
  
              .details-order-status-wrapper {
                flex-direction: row;
                justify-content: space-between;
                font-size: $size14;
              }
  
              .details-payment-date-wrapper {
                flex-direction: row;
                justify-content: space-between;
                margin-left: 0; 
                font-size: $size14;
              }
              
              .details-button-area {
  
                .simple-button {
                  width: -webkit-fill-available;
                }
              }
            }
          }
        }

        .customer-details-container {
          margin-top: $size16;

          .customer-details-title {
            font-size: $size14;
          }

          .customer-details-wrapper {
            display: flex;
            flex-direction: column;

            .customer-email-wrapper,
            .customer-name-wrapper {
              flex-direction: row;
              justify-content: space-between;
              font-size: $size14;
            }
          }
        }
      }
    }

    .payment-details-container {
      margin-top: $size16;
      height: 127px;
      border-top: 1px solid $medium-grey-blue;

      .payment-details-title {
        margin-top: $size24;
        font-size: $size16;
        font-weight: bold;
        text-align: center;
        color: $primary-black;
        text-align: left;
      }

      .payment-details-wrapper {
        margin-top: $size16;
        display: grid;
        grid-template-columns: minmax(220px, 0.6fr) minmax(50px, 0.6fr) minmax(110px, 0.6fr);
        text-align: left;

        .details-order-status-wrapper {
          flex-direction: column;
          line-height: $size24;

          .details-order-status-title {
            font-weight: bold;
            color: $dark-grey-blue;
          }

          .status-and-icon-wrapper {
            font-size: $size14;
            gap: $size8;
            align-items: center;

            .processed {
              color: $success-green;
            }

            .processing {
              padding-top: 1px;
              color: $dark-yellow;
            }

            .cancelled {
              color: $red;
            }
          }
        }

        .details-payment-date-wrapper {
          flex-direction: column;
          line-height: $size24;
          margin-left: $size24;

          .details-payment-date-title {
            font-weight: bold;
            color: $dark-grey-blue;
          }
        }

        .details-button-area {
          gap: $size8;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .submit-button {
            color: #006eab;
            border: solid 1px #006eab;
            background-color: #fff;
          }
        }
      }
    }

    .customer-details-container {
      margin-top: $size8;
      height: 127px;
      border-top: 1px solid $medium-grey-blue;

      .customer-details-title {
        margin-top: $size24;
        font-size: $size16;
        font-weight: bold;
        text-align: center;
        color: $primary-black;
        text-align: left;
      }

      .customer-details-wrapper {
        margin-top: $size16;
        display: grid;
        grid-template-columns: minmax(100px, 1fr) minmax(150px, 0.4fr) minmax(150px, 0.5fr) minmax(250px, 286px);
        text-align: left;

        .customer-email-wrapper,
        .customer-name-wrapper {
          flex-direction: column;
          line-height: $size24;

          .customer-email-title,
          .customer-name-title {
            font-weight: bold;
            color: $dark-grey-blue;
          }

          .customer-email,
          .customer-title {
            color: $light-blue-primary;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}