@import "../../styles/shared";

.general-information-wrapper {
  padding: $size24 $size16;

  .general-information-expand-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    background-color: $light-grey-blue;

    .general-information-expand-header {
      display: flex;
      margin: $size16;
      align-items: center;
      cursor: pointer;

      .title {
        margin: 0;
        font-size: $size14;
        font-weight: bold;
        text-align: left;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        margin-left: auto;
        border-radius: 12px;
      }
    }
  }

  .wrapper-unactive {
    background-color: $white;
    border-bottom: 1px solid $scrollbar-grey;
  }

  .list-wrapper {
    padding: 0 $size16 $size14 $size16;

    .list-info {
      width: 50%;

      .section-title {
        margin: $size32 0 $size16 0;
        color: $dark-grey-blue;
        font-size: $size14;
        text-align: left;
        font-weight: 500;
      }

      .empty-section {
        padding-bottom: $size16;
      }

      p {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      span {
        text-align: left;
        font-size: $size14;
        color: $dark-grey-blue;
      }

      .label {
        margin: 0;
        width: 35%;
        color: $primary-black;
        font-weight: bold;
        line-height: $size18;
      }

      .label-data {
        width: 65%;
        margin: 0 0 0 $size8;
        word-wrap: break-word;
        line-height: $size18;
      }

      .break-email-address {
        word-break: break-all;
      }
    }

    .product-location {
      margin-top: 14px;
      position: relative;
      max-width: 640px;
      width: 65%;

      img {
        max-width: 100%;
      }

      .mask-map {
        box-sizing: border-box;
        height: 94%;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;

        a {
          display: inline-block;
          height: 100%;
          opacity: 0;
          position: relative;
          transition: opacity 0.5s 0s ease-in-out;
          width: 100%;
        }

        .larger-map {
          font-size: 0.9rem;
          position: absolute;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: $size16 0;

    .general-information-expand-wrapper {
      .list-wrapper {
        flex-direction: column;
        gap: $size32;

        .list-info {
          width: 100%;

          .section-title {
            margin: 0 0 $size16 0;
          }

          .empty-section {
            display: none;
          }

          p {
            .label-data {
              text-align: right;
            }
          }
        }

        .product-location {
          margin-top: 0px !important;
        }

        .empty-list-info {
          display: none;
        }
      }
    }
  }
}
