@import "../../styles/shared.scss";

.view-role-modal,
.create-role-modal,
.edit-role-modal {
  .page-modal {
    .page-header {
      .status-wrapper {
        .title {
          font-weight: 500;
          font-size: $size14;
        }
        .toggle-wrapper {
          margin: 0 $size4 0 $size16;
        }

        .uneditable-toggle {
          .toggle-wrapper {
            cursor: default;
            pointer-events: none;
            opacity: 0.3;
          }
        }
      }
    }
    .page-content {
      .loading-spinner {
        margin-top: 0 !important;
        left: calc(50% - 1.5em);
      }

      .details-wrapper {
        .detail-wrapper {
          .title {
            font-size: $size14;
            color: $black;
            font-weight: 500;
          }

          .click-outside-wrapper {
            max-width: 100%;
            box-shadow: none;
            border: 1px solid #e5e7f0;
            box-sizing: border-box;

            .selected-option {
              color: $black;
            }

            .options-wrapper {
              position: relative;

              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }

          textarea,
          .click-outside-wrapper {
            margin-top: $size8;
          }

          .input-item:disabled,
          textarea:disabled {
            color: $disabled-grey;
            background-color: $white;
          }

          .description {
            margin-top: $size8;
          }

          .input-error {
            border: 1px solid $error-color;
            border-radius: $element-border-radius;
          }
        }

        .with-margin-top {
          margin-top: $size16;
        }
      }
    }
    .page-footer {
      .bottom-actions-wrapper {
        width: 100%;
        gap: $size8;

        .right-actions {
          gap: $size8;
        }
      }

      @media screen and (max-width: 768px) {
        button {
          width: 32%;
        }
      }
    }
  }
}

.view-role-modal {
  .details-wrapper {
    .details-first-row {
      .detail-wrapper {
        width: 50%;
      }
    }

    .details-third-row {
      margin-top: $size24;

      .detail-wrapper {
        .readonly-description {
          max-width: 640px !important;
        }
      }
    }

    .with-margin-top {
      margin-top: $size24;
    }
  }

  .policies-header {
    margin-top: $size24;
    .title {
      font-size: $size14;
      font-weight: 500;
    }
  }

  .policies-wrapper {
    width: 592px;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-top: $size8;

    .policy-wrapper {
      width: 33%;
      padding-bottom: $size8;
      gap: $size8;

      @media screen and (max-width: 480px) {
        width: 50%;
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.create-role-modal,
.edit-role-modal {
  .page-modal {

    textarea {
      width: 100%;
      font-size: $size14;
      font-family: Roboto, sans-serif;
      margin-top: $size8;
      resize: none;
      text-align: start;
      border-radius: $element-border-radius;
      border: 1px solid $medium-grey-blue;
      padding: 10px;
      box-sizing: border-box;
      outline: none;
    }
    
    .input-item {
      width: 100%;
      margin-top: 8px;
      height: $size36;
      border: 1px solid $medium-grey-blue;
      border-radius: $element-border-radius;
      text-indent: $size16;
      outline: none;
      box-sizing: border-box;
    }

    .details-wrapper {
      .checkbox {
        display: flex;
      }

      .select-label {
        font-size: $size14;
        font-weight: 500;
      }

      .add-roles-wrapper {
        flex-wrap: wrap;

        .add-role-wrapper {
          width: 33%;
          align-items: center;
          padding-bottom: 20px;

          .checkbox {
            display: flex;
          }
        }

        .label {
          font-size: $size14;
          font-weight: 500;
        }
      }

      .details-first-row {
        .detail-wrapper {
          width: calc(50% - 8px);

          textarea {
            height: $size36;
            padding-top: $size8;
          }
        }
      }

      .details-third-row {
        margin-top: $size16;
        textarea {
          height: 150px;
        }
      }

      .wrappable-description {
        white-space: normal !important;
        overflow: auto !important;
      }
    }

    .policies-header {
      margin-top: $size24;

      .title {
        font-size: $size18;
        font-weight: 500;
      }
    }

    .policies-wrapper {
      display: flex;
      max-width: 592px;
      margin-top: $size8;
  
      .columns-wrapper {
        column-count: 3;
        max-width: 592px;
  
        .policy-wrapper {
          break-inside: avoid;
          align-items: flex-start;
          padding-bottom: 7px;
          padding-top: 3px;
          color: #08232f;
          box-sizing: border-box;
          font-size: $size14;
    
          .checkbox-wrapper {
            display: flex;
            align-items: flex-start;

            svg {
              flex-shrink: 0;
            }
          }
        }
      }
  
      @media screen and (max-width: 768px) {
        .columns-wrapper {
          column-count: 2;
        }
      }
    }

    .status-wrapper {
      margin-top: $size16;
      .title {
        font-weight: 500;
        font-size: $size14;
      }
      .toggle-wrapper {
        margin: 0 $size4 0 $size16;
      }
    }
    .uneditable-toggle {
      .toggle-wrapper {
        cursor: default;
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
}

.delimiter {
  margin: $size24 0;
  height: 1px;
  background: $medium-grey-blue;
}
