@import "../../../../styles/shared.scss";

.image-builder-section {
  position: relative;

  .image-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}