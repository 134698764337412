@import '../../styles/shared';

.product-activation-steps-modal-wrapper {
  .page-modal-wrapper {
    height: 100vh;
    
    .page-modal {
      min-width: min-content;
      height: max-content; 
      max-height: 90vh; 

      .page-content {
        padding: $size24;
        height: calc(100% - 69px);

        .title {
          font-size: $size14;
          color: $primary-black;
          font-weight: 500;
          margin-bottom: $size24;
        }

        .product-activtion-steps-modal-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          min-height: 540px;    
        } 

        .instruction-wrapper {
          margin-bottom: $size4;
          font-size: $size14;
        }

        .thumbnail-wrapper {
          position: relative;
          display: flex;
          width: 100%;
          height: 320px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: $element-border-radius;
          margin-top: $size32;

          .thumbnail-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            svg{
              width: $size64;
              height: $size64;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $element-border-radius;
          }
        }

        .download-link-wrapper {
          margin-top: $size32;
          font-size: $size14;

          .download-link {
            margin-left: $size4;
            color: $primary-blue;
            font-weight: bold;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      .page-footer {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      height: 100%;
      top: 0px;
    }
  }
}