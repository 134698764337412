@import "../../styles/shared";

.template-editor-wrapper {
  height: 69vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: $size8 0 $size24 0;
    padding-bottom: $size16;
    border-bottom: 1px solid $medium-grey-blue;

    .title {
      font-size: $size24;
      font-weight: 700;
      color: $primary-black;

      .edit-title {
        font-weight: 400;
        color: $primary-blue;
        font-size: $size16;
        margin-left: $size16;
        cursor: pointer;
      }
    }

    .actions-wrapper {
      display: flex;
      gap: $size16;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    gap: $size16;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .editor-container {
      display: block;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid $medium-grey-blue;
  
      .text {
        color: $white;
        height: auto;
        cursor: pointer;
      }
  
      .link {
        height: 62px;
        width: max-content;
        background-color: $primary-blue;
        color: $white;
        padding: 24px 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 20px;
        border-radius: 8px;
        text-decoration: none;
      }

      .flex-container {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;

        .text {
          text-align: start;
        }
      }
    }

    .toolbox {
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: $size16;
      height: 100%;
      width: 20%;
      flex-shrink: 0;
      border-radius: $element-border-radius;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;

      .title {
        color: $primary-black;
        font-weight: 500;
        font-size: 18px;
      }

      .element-title {
        display: flex;
        gap: $size16;
        color: $primary-black;
        font-weight: 500;
        margin: $size8 0;
        height: $size36;
        align-items: center;

        .back-icon {
          width: $size24;
          height: $size24;
    
          &:hover {
            cursor: pointer;
          }
        }
      }

      .empty-selection {
        margin-top: $size24;
      }

      .layout-shift-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: auto;
        gap: $size8;

        .item {
          display: flex;
          height: $size36;
          align-items: center;

          .arrow-up {
            margin: 0 $size16 0 $size8;
            width: 14px;
            height: 13px;
            transform: rotate(90deg);
            cursor: pointer;
  
            path {
              fill: $black;
            }
          }
  
          .arrow-down {
            width: 14px;
            height: 13px;
            transform: rotate(270deg);
            cursor: pointer;
  
            path {
              fill: $black;
            }
          }

          .disabled-shift {
            cursor: default;
            pointer-events: none;

            path {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}