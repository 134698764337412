@import "../../../styles/shared";

.my-account-wrapper {
  .account-details-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 790px;
    margin-top: $size16;
    box-sizing: border-box;
    gap: $size16;

    .sections-wrapper {
      display: flex;
      flex-direction: column;
      gap: $size16;
      width: 100%;
      padding: 0 $size24 $size16 $size24;
      border-radius: $element-border-radius;
      background-color: $white;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;

      .wrapper-title {
        font-size: $size14;
        font-weight: 500;
        color: $dark-grey-blue;
        padding: $size24 0 $size8 0;
        margin: 0;
        text-transform: uppercase;
      }

      .section {
        display: flex;
        width: 100%;
        max-width: 1134px;
        box-sizing: border-box;
        align-items: center;

        .section-title {
          display: flex;
          flex-direction: column;
          width: 25%;
          height: 100%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
          justify-content: center;

          .disclaimer {
            margin: 0;
            font-size: $size14;
            color: $dark-grey-blue;
            font-weight: 400;
          }
        }

        .section-content {
          width: 75%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: $size16;

          .input-item-wrapper {
            display: flex;
            align-items: center;
            gap: $size8;
            padding: 0 $size16;
            width: 32%;
            height: $size36;
            border: 1px solid $medium-grey-blue;
            border-radius: $element-border-radius;
            box-sizing: border-box;

            .input-item {
              border: none;
              background-color: $white;
              outline: none;
              box-sizing: border-box;
              width: 100%;
            }

            .input-item:disabled {
              opacity: 0.7;
            }

            .input-item-icon {
              width: $size16;
              height: $size16;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
            }

            .is-action {
              cursor: pointer;
            }

            .error-message {
              position: absolute;
              font-size: $size12;
              color: $error-color;
              margin-top: 62px;
            }
          }

          .google-address-input-wrapper {
            .google-places-wrapper {
              top: 40px !important;
            }
          }

          .has-error {
            border: 1px solid $error-color;
          }

          .cancel-button {
            margin-left: auto;
          }

          .larger-input {
            width: calc(64% + 16px);
          }

          .disclaimer {
            margin: 0;
            font-size: $size14;
            color: $dark-grey-blue;
          }

          .avatar-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;

            .file-input-wrapper {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              cursor: pointer;

              .avatar-img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;

                .image {
                  border-radius: 50%;
                  background-size: auto;
                  background-position: center;
                  height: 80px;
                  width: 80px;
                  object-fit: cover;
                }

                .avatar-initials {
                  background-color: $dark-grey-blue;
                  color: $white;
                  width: inherit;
                  height: inherit;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: $size24;
                  flex-shrink: 0;
                }
              }

              svg {
                position: relative;
                right: -55px;
                bottom: 25px;
                z-index: 1;
              }
            }

            .file-input {
              display: none;
            }
          }

          .remove-image-button {
            margin-left: $size40;
          }         

          .click-outside-wrapper {
            margin: 0;
            border: 1px solid $medium-grey-blue; 
            box-shadow: none;
            max-width: 32%;
            box-sizing: border-box;

            .height {
              div {
                color: $primary-black;
                font-size: $size14;
              }         

              svg {
                width: 12px;
                height: 12px;
                path {
                  fill : $primary-black;
                }
              }

              .icon-dropdown-open {
                transform: rotate(180deg);
              }
            }

            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 160px;
              position: relative;
              overflow: overlay;

              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }

          .subscription-wrapper {
            display: flex;
            gap: $size8;
            width: 100%;
            align-items: flex-start;

            p {
              margin: 0;
              font-size: $size14;
              color: $primary-black;
            }

            .link {
              margin-left: $size4;
              color: $primary-blue;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        
        .info-icon {
          font-size: $size14;
          gap: $size4;

          .tooltiped-icon-wrapper {
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
            
            .tooltip {
              width: 250px;
              right: -131px;
            }

            svg {
              width: 20px;
            }

            .tooltip-text {
              text-align: left;
            }

            .tooltip-header {
              display: block;
              font-weight: bold;
              margin: $size4 0;
              font-size: $size14;
            }

            .tooltip-body {
              font-size: $size12;
              margin-bottom: $size8;
              line-height: 1.4;
            }
          }
        }

        .buttons {
          margin-top: $size24;
        }
      }

      .separator {
        background-color: $medium-grey-blue;
        width: 100%;
        height: 1px;
        margin: $size16 0;
      }

      .full-width {
        max-width: unset;
      }
    }    
  }

  @media all and (max-width: 768px) {
    .account-details-wrapper {

      .sections-wrapper {
        padding: 0 $size16 $size16 $size16;
        border-radius: 0;
        
        .section {
          flex-direction: column;
          align-items: flex-start;
          gap: $size8;

          .section-title {
            width: 100%;

            p {
              margin-top: 0;
            }
          }

          .empty {
            display: none;
          }

          .section-content {
            width: 100%;
            flex-direction: column;
            gap: $size8;

            .input-item-wrapper {
              width: 100%;
            }

            .simple-button {
              width: 100%;
            }

            .avatar-wrapper {
              width: 100%;
              justify-content: center;
            }

            .remove-image-button {
              margin: $size8 0 0 0;
            }

            .click-outside-wrapper {
              max-width: 100%;
            }
          }
          
          .info-icon {
            flex-direction: row;

            p {
              margin: 0px;
            }
          }

          .buttons {
            display: flex !important;
            flex-direction: row !important;
          }
        }
      }
    }
  }
}