@import "../../../../styles/shared.scss";

.carousel-builder-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .arrow {
    cursor: pointer;
    user-select: none;
  }

  .arrow-right {
    transform: rotate(180deg);
  }
}