@import "../../styles/shared";

.events-wrapper {

  .wrapped-tabs-wrapper {

    .arrow {
      background-color: $grey-background;

      &.arrow-left {
        border-right: 10px solid rgba(245, 246, 250, .7);
      }
  
      &.arrow-right {
        border-left: 10px solid rgba(245, 246, 250, .7);
      }
    }
  }

  .system-events-content-wrapper {
    .actions-wrapper {
      display: flex;
      align-items: center;
      height: $size36;
      width: 100%;
      margin-top: $size16;
      margin-bottom: $size16;
      box-sizing: border-box;

      .download-button {
        display: flex;
        align-items: center;
        margin-left: auto;
        border: none;
        margin-right: $size16;
        width: max-content;
        padding: $size16;
        height: $size36;
        background-color: transparent;
        cursor: pointer;

        svg {
          margin-right: $size8;
        }
      }
    }

    .description-wrapper {
      max-width: 250px;
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .device-notifications-content-wrapper {
    ///////////////****************************************
    .input-option {
      margin-top: 3em;
      font-weight: 500;
      font-size: $size16;
    }
    ///////////////****************************************

    .actions-wrapper {
      display: flex;
      align-items: center;
      height: $size36;
      width: 100%;
      margin-top: $size16;
      margin-bottom: $size16;
    }

    .hidden {
      display: none;
    }

    .add-notification-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: $size30 $size24 $size24 $size24;
    }

    .device-notifications-table {
      margin-top: $size16;
    }

    .status-wrapper {
      display: flex;
      align-items: center;
      width: 150px;

      .status-active {
        color: $success-green;
        display: flex;
        align-items: center;
      }

      .status-inactive {
        display: flex;
        align-items: center;
        color: $dark-grey-blue;
      }

      svg {
        margin-right: $size8;
      }
    }

    .table-actions-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: end;
    }

    .with-margin-top {
      margin-top: $size16;
    }
  
    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .page-modal-wrapper {

    .page-modal {

      .page-header {
        .status-wrapper {
          display: flex;
          align-items: center;

          .input-option {
            margin: 0 $size16 0 0;
            font-size: $size14;
            font-weight: 500;
            color: $primary-black;
          }

          .status-option {
            display: flex;
            align-items: center;

            .text-status-option {
              margin: 0 0 0 $size4;
              font-size: $size12;
              color: $primary-black;
            }
          }
        }
      }

      .notification-modal-content {
        width: 100%;
        box-sizing: border-box;

        .input-option {
          font-weight: 500;
        }

        .name-input {
          width: 100%;
          height: $size36;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          text-indent: $size16;
          outline: none;
          box-sizing: border-box;
        }

        .notification-title {
          font-size: $size14;
        }

        .description-input {
          width: 100%;
          height: 100px;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          padding: $size16;
          line-height: $size24;
          box-sizing: border-box;
          outline: none;
          font-family: 'Roboto';
          font-size: $size14;
        }

        .notification-message {
          font-size: $size14;
        }

        .edit-option-wrapper {
          display: flex;
          margin-top: $size16;

          .input-edit-option {
            margin: 0 $size8 0 0;
            font-weight: 500;
          }

          .text-edit-option {
            font-size: $size12;
            color: $primary-black;
            margin: 0 0 0 $size8;
          }
        }

        .edit-option-tooltip {
          font-size: $size12;
          color: $dark-grey-blue;
          margin: $size8 0 $size24 0;
        }

        .dropdown-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: $size24;
          gap: $size16;

          .email-template-wrapper,
          .type-wrapper,
          .device-event-wrapper {
            width: 100%;
            box-sizing: border-box;

            .input-option {
              margin: 0;
              font-weight: 500;
            }
            
            .name-input {
              margin-top: 1rem;
              font-size: $size14;
            }

            .click-outside-wrapper {
              margin: $size8 0 0 0;
              border: 1px solid $medium-grey-blue;
              box-shadow: none;
              max-width: 100%;
              box-sizing: border-box;
              cursor: pointer;

              .height {
                div {
                  color: $primary-black;
                  font-size: $size14;
                }

                svg {
                  width: 12px;
                  height: 12px;
                  path {
                    fill: $primary-black;
                  }
                }

                .icon-dropdown-open {
                  transform: rotate(180deg);
                }
              }

              .options-wrapper {
                margin-top: 4px;
                border: 1px solid $medium-grey-blue;
                max-height: 160px;
                position: relative;
                overflow: overlay;

                .selected-option {
                  color: $primary-black;
                  font-weight: 500;
                }
              }
            }

            .has-error {
              border: 1px solid $required-red;
            }
          }

          .non-device-type-wrapper {
            width: 100%;
            box-sizing: border-box;

            .input-option {
              font-weight: 500;
            }

            .click-outside-wrapper {
              margin: $size8 0 0 0;
              border: 1px solid $medium-grey-blue;
              box-shadow: none;
              max-width: 100%;
              box-sizing: border-box;
              cursor: pointer;

              .height {
                div {
                  color: $primary-black;
                  font-size: $size14;
                }

                svg {
                  width: 12px;
                  height: 12px;
                  path {
                    fill: $primary-black;
                  }
                }

                .icon-dropdown-open {
                  transform: rotate(180deg);
                }
              }

              .options-wrapper {
                margin-top: 4px;
                border: 1px solid $medium-grey-blue;
                max-height: 160px;
                position: relative;
                overflow: overlay;

                .selected-option {
                  color: $primary-black;
                  font-weight: 500;
                }
              }
            }

            .has-error {
              border: 1px solid $required-red;
            }
          }

          .email-template-preview-button {
            width: 50%;
            box-sizing: border-box;
            display: flex;
            cursor: pointer;

            .template-preview-tooltip {
              color: $primary-blue;
              margin-top: 32px;
            }

            .disabled {
              opacity: 0.5;
            }
          }
        }

        .without-margin-bottom {
          margin-bottom: 0;
        }

        .has-error {
          border: 1px solid $required-red;
        }

        .error-text {
          color: $required-red;
          font-size: 12px;
        }

        .info-wrapper {
          display: flex;
          margin: 0 0 $size16;
          padding: $size16;
          border-radius: $element-border-radius;
          background-color: $transparent-blue;
          color: $primary-black;
        }

        .notification-expiry-date-wrapper {

          .react-datepicker-wrapper {
            width: 100%;
            margin-bottom: $size8;

            .custom-date-input {
              display: flex;
              background-color: $white;
              height: 40px;
              width: 100%;
              margin: $size8 0 0 0;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              .date-text {
                font-size: $size14;
                margin-left: $size16;
              }

              .reset-date-button {
                background: none;
                border: none;
                width: 24px !important;
                height: 24px !important;
                cursor: pointer;
                margin-left: auto;
                margin-right: $size8;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  flex-shrink: 0;
                  width: 16px !important;
                  height: 16px !important;

                  g {
                    stroke: $dark-grey-blue;
                  }
                }
              }

              .calendar-icon {
                width: 16px;
                height: 16px;
                margin-right: $size8;

                path {
                  fill: $primary-black;
                }
              }
            }
          }

          .react-datepicker-popper {
            margin-top: $size4;
            z-index: 9;

            .react-datepicker {
              font-size: 1rem;
              border-radius: $element-border-radius;
              border: 1px solid $medium-grey-blue;
              box-shadow: -3px 4px 16px 0 rgba(0, 0, 0, 0.05);
              font-family: "Roboto";
  
              .react-datepicker__triangle {
                display: none;
              }
  
              .react-datepicker__header {
                background-color: $light-grey-blue;
                border-bottom: 1px solid $medium-grey-blue;
                display: flex;
                flex-direction: column;
                gap: $size8;

                .react-datepicker__current-month {
                  font-size: $size14;
                  font-weight: 700;
                  color: $primary-black;
                }

                .react-datepicker__day-names {
                  font-weight: 500;
                }

                .react-datepicker__header__dropdown {
                  display: flex;
                  justify-content: space-between;
                  padding: 0 $size16;

                  .react-datepicker__month-dropdown-container {
                    margin: 0;

                    .react-datepicker__month-read-view {
                      text-align: left;

                      .react-datepicker__month-read-view--down-arrow {
                        margin-left: $size4;
                      }
                    }
                  }

                  .react-datepicker__year-dropdown-container {
                    margin: 0;
                  }
                }
              }
              
              .react-datepicker__month {
                .react-datepicker__week {
                  
                  .react-datepicker__day {
                    border-radius: 50%;
                  }

                  .react-datepicker__day--selected {
                    background-color: $primary-blue !important;
                  }
    
                  .react-datepicker__day--selected:hover {
                    background-color: $primary-blue !important;
                  }

                  .react-datepicker__day--keyboard-selected {
                    background-color: $primary-blue !important;
                    border-radius: 50%;
                  }

                  .react-datepicker__day--keyboard-selected:hover {
                    background-color: $primary-blue !important;
                    border-radius: 50%;
                  }              
                }
              }

              .react-datepicker__year {
                .react-datepicker__year-text--keyboard-selected {
                  background-color: $primary-blue !important;
                }

                .react-datepicker__year-text--keyboard-selected:hover {
                  background-color: $primary-blue !important;
                }

                .react-datepicker__year-text--selected {
                  background-color: $primary-blue !important;
                }

                .react-datepicker__year-text--selected:hover {
                  background-color: $primary-blue !important;
                }
              }

              .react-datepicker__month-dropdown {
                background-color: $white;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                font-size: $size14;
                padding: $size8 0;
                width: 60%;
                left: 20%;

                @media screen and (max-width: 768px) {
                  width: 80%;
                  left: 10%;
                }

                .react-datepicker__month-option:hover {
                  background-color: $light-grey-blue;
                }
              }

              .react-datepicker__year-dropdown {
                background-color: $white;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                font-size: $size14;
                padding: $size8 0;

                .react-datepicker__year-option:hover {
                  background-color: $light-grey-blue;
                }
              }
            }
          }
        }
      }

      .template-preview-modal-content {
        display: flex;
        flex-direction: column;

        .template-preview-content {
          width: max-content;
          height: 100%;
          box-sizing: border-box;
          align-self: center;

          .content {
            min-height: unset !important;
          }
        }
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 768px) {
    .system-events-content-wrapper {

      .actions-wrapper {
        height: unset;
        flex-direction: column;
        padding: 0 $size16;

        .download-button {
          margin: $size16 0 0 0;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        .order-by-mobile-wrapper {
          margin-top: $size16;
          height: $size36;
          width: 100%;
  
          .click-outside-wrapper {
            margin: 0 $size16 0 0;
            border: 1px solid $medium-grey-blue;
            box-shadow: none;
            max-width: 100%;
            cursor: pointer;
  
            .height {
              div {
                color: $primary-black;
                font-size: $size14;
              }
  
              svg {
                width: 12px;
                height: 12px;
                path {
                  fill: $primary-black;
                }
              }
  
              .icon-dropdown-open {
                transform: rotate(180deg);
              }
            }
  
            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 390px;
              position: relative;
              overflow: overlay;
  
              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }
        }
      }

      .card {
        width: 100%;
        min-height: $size48;
        height: max-content;
        margin-top: $size4;
        background-color: $white;
        padding: $size16 $size8 $size16 $size16;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size8;
        cursor: pointer;
  
        .card-item {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: $size8;
  
          .card-item-title {
            width: 30%;
            font-size: $size14;
            font-weight: 700;
            color: $primary-black;
            margin: 0;
          }
  
          .card-item-body {
            width: 70%;
            padding-right: $size32;
            font-size: $size14;
            color: $primary-black;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: flex-end;
            text-align: end;
          }

          .align-center {
            align-items: center;
          }
        }
  
        .align-center {
          align-items: center;
        }
      }
  
      .buttons-wrapper {
        margin-top: $size16;
        margin-bottom: $size16;
      }
    }

    .device-notifications-content-wrapper {

      .hidden {
        display: none;
      }

      .add-notification-button {
        position: absolute;
        top: 64px;
        left: 16px;
        right: 16px;
        margin: $size16 0 $size8 0;
        width: calc(100% - 32px);
  
        .simple-button {
          width: 100%;
        }
      }

      .actions-wrapper {
        height: unset;
        flex-direction: column;
        padding: 0 $size16;

        .order-by-mobile-wrapper {
          margin-top: $size16;
          height: $size36;
          width: 100%;
  
          .click-outside-wrapper {
            margin: 0 $size16 0 0;
            border: 1px solid $medium-grey-blue;
            box-shadow: none;
            max-width: 100%;
            cursor: pointer;
  
            .height {
              div {
                color: $primary-black;
                font-size: $size14;
              }
  
              svg {
                width: 12px;
                height: 12px;
                path {
                  fill: $primary-black;
                }
              }
  
              .icon-dropdown-open {
                transform: rotate(180deg);
              }
            }
  
            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 390px;
              position: relative;
              overflow: overlay;
  
              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }
        }
      }

      .card {
        width: 100%;
        min-height: $size48;
        height: max-content;
        margin-top: $size4;
        background-color: $white;
        padding: $size16 $size8 $size16 $size16;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size8;
        cursor: pointer;
  
        .card-item {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: $size8;
  
          .card-item-title {
            width: 30%;
            font-size: $size14;
            font-weight: 700;
            color: $primary-black;
            margin: 0;
          }
  
          .card-item-body {
            width: 70%;
            padding-right: $size32;
            font-size: $size14;
            color: $primary-black;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: flex-end;
            text-align: end;

            .card-actions {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              position: absolute;
              right: $size16;
  
              .actions-menu-wrapper {
                right: 0;
              }
            }
            
            .card-item-body-status {
              display: flex;
              gap: $size8;

              p {
                margin: 0;
              }

              .status-active {
                color: $success-green;
                display: flex;
                align-items: center;
              }

              .status-inactive {
                display: flex;
                align-items: center;
                color: $dark-grey-blue;
              }
            }
          }

          .align-center {
            align-items: center;
          }
        }
  
        .align-center {
          align-items: center;
        }
      }

      .with-margin-top {
        margin-top: $size16;
      }
  
      .buttons-wrapper {
        margin-top: $size16;
        margin-bottom: $size16;
      }
    }

    .page-modal-wrapper {
      .page-modal {        

        .page-header {
          .status-wrapper {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        .notification-modal-content {
          font-size: $size14;

          .dropdown-wrapper {
            margin-bottom: $size16;
            flex-direction: column;
            gap: $size16;
            width: 100%;
            box-sizing: border-box;

            .email-template-wrapper,
            .type-wrapper,
            .device-event-wrapper {
              width: 100%;

              .click-outside-wrapper {
                max-width: 100%;
              }
            }

            .email-template-preview-button {
              width: 100%;

              .template-preview-tooltip {
                margin-top: $size24;
              }
            }
          }

          .without-margin-bottom {
            margin-bottom: 0;
          }
        }

        .template-preview-modal-content {

          .template-preview-content {
            width: 100%;
          }
        }

        .page-footer {
          .cancel-button {
            width: 100%;
          }

          .submit-button {
            width: 100%;
          }
        }
      }
    }
  }

  @media all and (max-width: 414px) {
    .device-notifications-content-wrapper {

      .hidden {
        display: none;
      }

      .add-notification-button {
        left: 16px !important;
        width: calc(100% - 32px) !important;
      }
    }

    .notification-message-modal {
      .page-title {
        font-size: 20px !important;
      }
    }
  }
}

.events-wrapper:has(.device-notifications-content-wrapper) {
  @media screen and (max-width: 768px) {
    .dashboard-container {
      height: 81px;
    }
  }
}
