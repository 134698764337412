@import "../../../styles/shared";

.menu-wrapper {
  background: $primary-black;
  color: $white;
  font-size: 1.175rem;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  height: 100%;
  position: fixed;
  top: 68px; //69px;
  left: 0;
  overflow-x: hidden;
  // transition: width 300ms linear 0s;
  max-width: 263px;
  width: 61px;
  z-index: 12;

  &::-webkit-scrollbar {
    display: none;
    width: 4.5px;
  }

  &::-webkit-scrollbar-track {
    background: #e9e9eb;
  }

  &.disabled-banner {
    top: 0;
  }

  &.nav-expand {
    width: 263px;

    .nav-delimiter {
      color: rgba(255, 255, 255, 0.322);
      font-size: 14px;
      padding-left: 20px;
    }

    .menu-container {
      > nav {
        width: 263px;

        ul > li > a {
          padding: $size16 $size24;
          justify-content: flex-start;

          .item-name {
            display: block;
            white-space: break-spaces;
          }
        }

        @media screen and (max-width: 1023px) {
          position: relative;
          width: 75vw;
        }
      }
    }

    @media screen and (max-width: 1023px) {
      width: 75vw;
    }
  }

  &:not(.nav-expand) {
    .nav-delimiter {
      height: 1.5px;
      width: 50%;
      margin: auto;
      background: $dark-grey-blue;
    }

    .nav-icon {
      &:hover {
        .tooltip {
          visibility: visible;
        }
      }
    }
  }

  .menu-container {
    margin-bottom: 84px;
  }

  .language-container {
    padding: 0;

    .language-wrapper {
      padding: 21px 23px; //21px 23px 21px 65px;
    }

    .language-options {
      background: #fff;
      color: #11232e;
      padding: 21px 23px;
    }

    @media screen and (min-width: 1024px) {
      display: none !important;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      cursor: pointer;
      font-size: 1rem; // 1.34rem;

      a {
        cursor: pointer;
        color: #fff;
        font-size: 1rem; //1.34rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: $size16;
        white-space: nowrap;
        transition: width 300ms linear 0s;
        box-sizing: border-box;
        gap: $size16;

        svg {
          .fillable-area {
            fill: $white !important;
          }
        }

        &:hover,
        &.active {
          background: #183343;
        }

        &.active {
          font-weight: bold;
          transition: width 300ms linear 0s;

          svg {
            .fillable-area {
              fill: $primary-blue !important;
            }
          }
        }

        .nav-icon {
          justify-content: center;
        }

        .item-name {
          display: none;
        }

        @media screen and (max-width: 414px) {
          font-size: 1.34rem;
        }
      }

      .nav-delimiter {
        color: rgba(255, 255, 255, 0.322);
        font-size: 12px;
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .nav-icon {
    position: relative;
    display: flex;
    justify-content: center;

    .tooltip {
      visibility: hidden;
      background-color: inherit;
      color: #fff;
      font-size: 0.55rem;
      text-align: center;
      border-radius: $element-border-radius;
      padding: 5px 0;
      top: 100%;
      position: absolute;
      white-space: break-spaces;
      max-width: 61px;
      width: 61px;
      z-index: 1;
    }
    
    .agreement-icon {
      width: 24px;
      height: 24px;
    }
  }

  @media screen and (max-width: 1023px) {
    top: 50px;
    max-width: 100%;
    width: 0;
  }
}

.drawer-menu {
  @media screen and (max-width: 1023px) {
    max-width: 75vw !important;
  }
}
