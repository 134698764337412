@import '../../styles/shared';

.cycle-faults-content-wrapper {

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
    box-sizing: border-box;

    .current-date {
      margin-left: auto;
      font-size: $size18;
      color: $primary-black;

      .medium {
        font-weight: 500;
      }
    }
  }

  .total-cycles-wrapper {
    display: flex;
    width: 100%;
    gap: $size16;
    box-sizing: border-box;
    margin-bottom: $size16;

    .cycles-card {
      display: flex;
      flex-direction: column;
      gap: $size16;
      width: 100%;
      padding: $size16;
      background-color: $white;
      border-radius: $element-border-radius;
      box-sizing: border-box;

      .title {
        font-size: $size14;
        color: $primary-black;
        font-weight: 700;
      }

      .data-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;

        .data-item {
          display: flex;
          flex-direction: column;
          gap: $size4;

          .value {
            font-size: $size16;
            color: $primary-black;
            font-weight: 700;
          }

          .label {
            font-size: $size14;
            color: $dark-grey-blue;
          }
        }
      }
    }
  }

  .product-wrapper {
    display: flex;
    gap: $size8;
    align-items: center;
    max-width: 200px;

    .product-image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        width: $size24;
        height: $size24;
        object-fit: contain;
      }
    }
  }

  .serial-number-wrapper {
    max-width: 150px;
  }

  .country-wrapper {
    display: flex;
    gap: $size8;
    align-items: center;

    svg {
      width: $size14;
      height: $size14;
    }

    p {
      margin: 0;
      font-size: $size14;
      color: $primary-black;
      white-space: nowrap;
    }
  }

  .cf-wrapper {
    color: $primary-blue;
    font-weight: 400;
  }

  .cf-date-wrapper {
    max-width: 150px;
  }

  .external-service-logo {
    object-fit: contain;
    width: $size24;
    height: $size24;
  }

  .status-wrapper {
    display: flex;
    gap: $size8;
    align-items: center;

    .status-in-progress {
      color: $primary-blue;
      display: flex;
      align-items: center;
    }

    .status-pending {
      color: $dark-yellow;
      display: flex;
      align-items: center;
    }

    .status-inactive {
      display: flex;
      align-items: center;
      color: $dark-grey-blue;      
    }

    p {
      margin: 0;
    }
  }

  .table-actions-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 768px) {
    .actions-wrapper {
      flex-direction: column;
      gap: $size16;
      height: unset;
      align-items: flex-start;
      padding: 0 $size16;
  
      .current-date {
        align-self: center;
        margin: 0;
      }
    }

    .total-cycles-wrapper {
      flex-direction: column;
      border-radius: 0;

      .cycles-card {
        border-radius: 0;
      }
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      border-radius: 0;
      padding: $size16 $size8 $size16 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 30%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 70%;
          padding-right: $size32;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;

          .card-description {
            display: flex;
            gap: $size8;
            align-items: center;
            text-align: right;

            .image-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              width: $size32;
              height: $size32;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;

            .actions-menu-wrapper {
              right: 0;
            }
          }

          .country-wrapper {
            display: flex;
            align-items: center;
            gap: $size8;

            svg {
              width: $size14;
              height: $size14;
            }

            p {
              margin: 0;
            }
          }

          .card-item-body-status {
            display: flex;
            gap: $size8;

            .status-in-progress {
              color: $primary-blue;
              display: flex;
              align-items: center;
            }

            .status-pending {
              color: $dark-yellow;
              display: flex;
              align-items: center;
            }

            .status-inactive {
              display: flex;
              align-items: center;
              color: $dark-grey-blue;      
            }

            p {
              margin: 0;
            }

            .external-service-logo {
              object-fit: contain;
              width: $size24;
              height: $size24;
            }
          }
        }

        .color-blue {
          color: $primary-blue;
          font-weight: 400;
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }

    .buttons-wrapper {
      margin-top: $size16;
      margin-bottom: $size16;
    }
  }
}
